<script setup>
import { ref, onMounted, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { API, HOST } from '@/utils/http'

import { toast } from '@/components/ui/toast'
import { FeaturesList } from '@/components/features'
import { Spinner } from '@/components/ui/spinner'
import { Eye, ChevronLeft } from 'lucide-vue-next'
import {
  Pagination,
  PaginationEllipsis,
  PaginationFirst,
  PaginationLast,
  PaginationList,
  PaginationListItem,
  PaginationNext,
  PaginationPrev,
} from '@/components/ui/pagination'
import { Button } from '@/components/ui/button'
import { BackButton } from '@/components/buttons'

const store = useStore()
const params = computed(() => store.getters['search/getParams'])
const totalInFocus = computed(() => store.getters['geo/getTotalOnMap'])
const isLoggedIn = computed(() => store.getters.getIsLoggedIn)

const router = useRouter()
const route = useRoute()
const projectId = computed(() => parseInt(route.params.project_id))

const features = ref(null)
const featuresLength = computed(() => features.value?.length)
const isFeatureCreated = computed(() => store.getters['geo/getIsFeatureCreated'])
const isLoading = ref(false)

const total = ref(0)
const page = ref(1)
const limit = ref(15)
const offset = computed(() => (page.value - 1) * limit.value)

const fetchFeatures = async () => {
  if (!projectId.value) return

  isLoading.value = true
  try {
    const paramsMap = params.value.reduce((acc, param) => {
      acc[param.key] = param.value;
      return acc
    }, {})

    const payload = {
      shared: paramsMap['shared'],
      has_resources: paramsMap['has_resources'],
      start_date: paramsMap['start_date'],
      end_date: paramsMap['end_date']
    }

    const fullPayload = {
      ...payload,
      south: paramsMap['south'],
      north: paramsMap['north'],
      east: paramsMap['east'],
      west: paramsMap['west'],
      limit: limit.value,
      offset: offset.value
    }

    const response = await API({
      method: 'GET',
      url: `${HOST}/geo/features/${projectId.value}`,
      params: fullPayload,
    })


    const response_total = await API({
      method: 'GET',
      url: `${HOST}/geo/features/${projectId.value}/total`,
      params: payload,
    })

    console.log('Feed: fetchData', `count: ${response.data.features.features.length}`)

    features.value = response.data.features.features
    total.value = response_total.data
  } catch (e) {
    if (e.response?.status === 403) {
      toast({title: `You don't have access to this data`})
    } else {
      throw new Error(e)
    }
  }

  isLoading.value = false
}

onMounted(async () => {
  if (isLoggedIn.value) {
    await fetchFeatures()
    console.log('Feed: onMounted')
  }
})

watch(
  () => isLoggedIn.value,
  async (newValue, oldValue) => {
    if (newValue && !oldValue) {
      await fetchFeatures()
      console.log('Feed: watch filterParams 1')
    }
  }
)

watch(
  () => projectId.value,
  async (newValue, oldValue) => {
    if (newValue !== oldValue) {
      await fetchFeatures()
      console.log('Feed: watch projectId')
    }
  }
)

watch(
  () => params.value,
  async (newValue, oldValue) => {
    if (!oldValue) return
    await fetchFeatures()
    console.log('Feed: watch filterParams')
  },
  { deep: true }
)

watch(
  () => page.value,
  async (newValue, oldValue) => {
    if (newValue !== oldValue) {
      await fetchFeatures()
      console.log('Feed: watch page')
    }
  }
)

watch(
  () => isFeatureCreated.value,
  async (value) => {
    if (value) {
      await fetchFeatures()
      store.commit('geo/SET_FEATURE_CREATED', false)
    }
  }
)

const paginationUpdated = async (pageCount) => {
  page.value = pageCount
}

const focusOnFilteredData = () => {
  store.commit('geo/SET_FOCUS_ON_FILTERED_DATA', true)
}
</script>

<template>
  <div class="px-2 py-1 border-b sticky top-0 bg-white">
    <div class="flex items-center justify-between">
      <h4 class="text-gray-500">Visible reports: {{ totalInFocus }} of {{ total }}</h4>
      <Button variant="ghost" size="sm" @click="focusOnFilteredData">
        <span class="mr-2 text-gray-700">Show All</span>
        <Eye class="text-gray-500 w-5 h-5" />
      </Button>
      <BackButton />
    </div>
  </div>
  <div v-if="featuresLength > 0">
    <FeaturesList :features="features" />

    <div v-if="totalInFocus > limit" class="p-3">
      <Pagination
        v-slot="{ page }"
        :total="totalInFocus"
        :sibling-count="1"
        show-edges
        :default-page="page"
        :items-per-page="limit"
        @update:page="paginationUpdated"
      >
        <PaginationList v-slot="{ items }" class="flex items-center gap-1">
          <PaginationFirst />
          <PaginationPrev />

          <template v-for="(item, index) in items">
            <PaginationListItem v-if="item.type === 'page'" :key="index" :value="item.value" as-child>
              <Button class="w-10 h-10 p-0" :variant="item.value === page ? 'default' : 'outline'">
                {{ item.value }}
              </Button>
            </PaginationListItem>
            <PaginationEllipsis v-else :key="item.type" :index="index" />
          </template>

          <PaginationNext />
          <PaginationLast />
        </PaginationList>
      </Pagination>
    </div>
  </div>
  <div v-else class="p-3 w-full h-full flex items-center justify-center">
    <div v-if="isLoading">
      <Spinner class="w-12 h-12" />
    </div>
    <div v-if="!isLoading && featuresLength === 0" class="text-center">
      <h3 class="text-base font-bold mb-1">No data found</h3>
      <p class="text-gray-500">Select project or check filters</p>
      <!-- <Button variant="outline" size="sm" @click="resetFilters">Reset filters</Button> -->
    </div>
  </div>
</template>
