<script setup>
import {useStore} from "vuex"
import {useRouter} from "vue-router"
import {ref} from 'vue'
import {File, Plus} from 'lucide-vue-next'
import {Button} from '@/components/ui/button'
import {Label} from '@/components/ui/label'

const store = useStore()
const router = useRouter()
const route = router.currentRoute
const fileUploadInput = ref(null)

const emit = defineEmits(['change'])

const setFiles = (e) => {
  if (route.name !== 'FileViewer') {
    router.push({name: 'FileViewer'})
  }

  store.commit('geo/SET_FILES', e.target.files)
  emit('change', e.target.files)
}

const resetFiles = () => {
  fileUploadInput.value.value = ''
  store.commit('geo/SET_FILES', null)
}
</script>

<template>
  <div class="flex flex-col justify-center items-center">
    <input
      ref="fileUploadInput"
      type="file"
      accept=".shp, .kml, .kmz, .geojson, .jpg, .jpeg, .png"
      @change="setFiles($event)"
      @click="resetFiles()"
      style="visibility: hidden; position: absolute; top: 0; left: 0; pointer-events: none;"
    />

    <Button @click="$refs.fileUploadInput.click()" class="w-9 h-9 rounded-full p-0 relative">
      <Plus
        class="w-3 h-3 absolute top-0 left-0 bg-secondary border rounded-full border-primary text-primary"
      />
      <File class="w-4 h-4 text-white" />
    </Button>
    <Label class="text-center">File</Label>
  </div>
</template>
