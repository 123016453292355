<script setup>
import {computed, ref} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {ChevronRight, Folder, FolderPlus, Settings, Pencil, Eye, FolderOpen} from 'lucide-vue-next'
import {bbox} from '@turf/turf'

import {formatTime} from '@/utils/datetime'

import {Button} from '@/components/ui/button'

const store = useStore()
const router = useRouter()
const emit = defineEmits(['select'])
const props = defineProps({
  project: Object,
  selected: Object,
  openedProjectIds: Object
})

const map = computed(() => store.getters['geo/getMap'])
const settings = computed(() => store.getters['user/getUserSettings'])

const isSelected = computed(() => props.selected?.project_id === props.project.project_id)
const isOpened = computed(() => !!props.openedProjectIds?.[props.project.project_id])
const isHovered = ref(false)

const select = (project, level = 0) => {
  router.push({ name: 'ProjectHome', params: { project_id: project.project_id } })
  emit('select', project, level)
}

const isoToEmoji = (isoCode) => {
  const codePoints = isoCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
}

const createProject = () => {
  router.push({ name: 'ProjectCreate', params: { project_id: props.project.project_id }})
}

const editProject = () => {
  router.push({ name: 'ProjectEdit', params: { project_id: props.project.project_id } })
}

const zoom = () => {
  if (!props.project.feature) return
  const bounds = bbox(props.project.feature)
  map.value.fitBounds(bounds, { padding: 50, duration: 600 })
}
</script>

<template>
  <div class="flex items-center px-0.5">
    <div
      class="flex items-center flex-1 w-full rounded-lg p-0.5 border cursor-pointer"
      :class="{'bg-secondary': isHovered, 'border-primary': isSelected, 'border-transparent': !isSelected}"
      :value="project"
      @mouseenter="isHovered = true"
      @mouseleave="isHovered = false"
    >
      <Button
        variant="ghostDark"
        class="w-7 h-7 rounded-full p-0"
        @click="select(project)"
      >
        <ChevronRight class="w-7 h-5 text-gray-400" :class="{'rotate-90 text-black': isOpened}" />
      </Button>
      <div class="flex items-center justify-between w-full">
        <div
          class="flex items-center w-full h-full p-0.5 cursor-pointer"
          @click="select(project)">
          <div class="w-4 h-4 mr-2 flex items-center justify-center">
            <span v-if="project.place_details">
              {{ isoToEmoji(project.place_details.country_code) }}
            </span>
            <Folder class="w-4 h-4 text-gray-700" />
          </div>
          <span class="truncate max-w-[150px]" :title="project.project_name">
            {{ project.project_name }}
          </span>
          <!-- <span v-if="project.children_count > 0" class="text-gray-400 ml-1">({{ project.children_count }})</span> -->
        </div>

        <div v-if="isHovered" class="flex items-center">
          <Button variant="ghostDark" class="w-7 h-7 rounded-full p-0" @click="createProject">
            <FolderPlus class="w-4 h-4 text-gray-500" />
          </Button>
          <Button variant="ghostDark" class="w-7 h-7 rounded-full p-0" @click="editProject">
            <Pencil class="w-4 h-4 text-gray-500" />
          </Button>
          <Button variant="ghostDark" class="w-7 h-7 rounded-full p-0" @click="router.push({ name: 'ProjectMembers', params: { project_id: project.project_id } })">
            <Settings class="w-4 h-4 text-gray-500" />
          </Button>
        </div>
      </div>
    </div>
  </div>

  <div class="pl-5 pr-1" v-if="isOpened">
    <ProjectListItem
      v-for="child in project.children"
      :key="child.project_id"
      :project="child"
      :selected="selected"
      :opened-project-ids="openedProjectIds"
      @select="(project, level) => select(project, level + 1)"
    />

    <!-- <div v-if="isSelected" class="bg-red-50 rounded-md p-1 mx-1">No data</div> -->
  </div>
</template>
