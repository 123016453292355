<script setup>
import { ref, computed, onBeforeMount, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import {Button} from "@/components/ui/button"
import {ChevronLeft} from "lucide-vue-next"
import {OrgSelector} from '@/components/filters'
import {DialogCreateProject, DialogCreateOrg} from '@/components/dialogs'

const router = useRouter()
const store = useStore()
const member = computed(() => store.getters.getMember)

watch(() => member.value, (value) => {
  if (!value.is_super_user) {
    router.push({name: 'Dashboard'})
  }
})
</script>

<template>
  <div v-if="member && member.is_super_user">
    <div class="flex items-center justify-between">
      <div class="flex items-center p-3">
        <Button size="sm" variant="ghost" class="w-8 p-0 h-8 mr-2 rounded-full" @click="$router.go(-1)">
          <ChevronLeft class="text-gray-500" />
        </Button>
        <h2 class="text-lg font-bold">Admin Tools</h2>
      </div>
    </div>
    <div class="px-3">
      <div class="space-y-3">
        <h3 class="font-bold">Organizations</h3>
        <div>
          <DialogCreateOrg>
            <Button variant="secondary" size="sm">+ Add organization</Button>
          </DialogCreateOrg>
        </div>
        <div>
          <org-selector v-if="$route.name !== 'Organizations'" />
        </div>
      </div>
      <div class="mt-3">
        <h3 class="font-bold mb-1">Projects</h3>
        <DialogCreateProject>
          <Button variant="secondary" size="sm">+ Add root/master project</Button>
        </DialogCreateProject>
      </div>
    </div>
  </div>
</template>
