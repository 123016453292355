<script setup>
import {computed, ref, onMounted, onBeforeUnmount, watch} from 'vue'
import {useStore} from 'vuex'
import {useRoute} from 'vue-router'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Switch } from '@/components/ui/switch'
import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/toast'
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select"
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import Label from "@/components/ui/label/Label.vue";
import { Textarea } from '@/components/ui/textarea'

const route = useRoute()
const projectId = computed(() => route.params.project_id)

const isOpen = ref(false)
const geojson = ref(null)
const selectedProjectId = ref(null)

const store = useStore()
const member = computed(() => store.getters['user/getMember'])
const projects = computed(() => store.getters['project/getProjects'])
const templates = computed(() => {
  const templates = store.getters['geo/getProjectReportTemplates']
  if (!templates) return []
  return templates.filter(t => t.geometry_type === geojson.value.geometry.type)
})

const open = (feature) => {
  isOpen.value = true
  geojson.value = feature
}

const close = () => {
  isOpen.value = false
}

const emit = defineEmits(['update:created'])

const schema = ref(z.object({
  color: z.string().default('#0059ff'),
  project_id: z.string().default(projectId.value ? projectId.value : null),
  template_id: z.string().optional(),
  name: z.string().min(3).max(255),
  description: z.string().min(3).max(255),
  shared: z.boolean().default(false),
  geofence: z.boolean().default(false),
  geofenceEvents: z.string().default(null).optional(),
  geofencePrompt: z.boolean().default(false),
}))

const onSubmit = async (values) => {
  await store.dispatch('geo/saveFeature', {
    project_id: values.project_id,
    feature: {
      type: 'Feature',
      properties: {
        name: values.name,
        description: values.description,
        shared: values.shared,
        member_id: member.value.member_id,
        created_origin: Date.now(),
        type: geojson.value.geometry.type,
        subtype: 'report', // deprecated, need to remove
        geofence: values.geofence,
        geofence_events: values.geofenceEvents === 'both' ? ['in', 'out'] : [values.geofenceEvents],
        geofence_prompt: values.geofencePrompt,
        template_id: parseInt(values.template_id),
        color: values.color,

        data: values.dataFields ? Object.entries(values.dataFields).map(([key, value]) => {
          return {
            key,
            value,
            type: typeof value === 'number' ? 1 : typeof value === 'boolean' ? 2 : 0
          }
        }) : []
      },
      geometry: geojson.value.geometry
    }
  })

  toast({
    title: `Geometry ${values.name} saved`,
  })

  emit('update:created', geojson.value)

  close()
}

defineExpose({
  open
})

const handleProjectChange = (id) => {
  selectedProjectId.value = id
  schema.value = schema.value.extend({
    project_id: z.string().default(id),
  })
  // store.dispatch('geo/fetchProjectReportTemplates', id)
}

const selectedTemplateId = ref(null)

const handleTemplateSelect = (id) => {
  const template = templates.value.find(t => t.id === parseInt(id))
  const dataFields = template.data_fields.reduce((acc, field) => {
    switch (field.type) {
      case 0:
        acc[field.key] = z.string().describe({ type: field.type})
        break
      case 1:
        acc[field.key] = z.number().describe({ type: field.type})
        break
      case 2:
        acc[field.key] = z.boolean().default(false).describe({ type: field.type})
        break
      case 3:
        acc[field.key] = z.string().describe({ type: field.type})
        break
      case 4:
        acc[field.key] = z.enum(field.options).describe({ type: field.type})
        break
    }
    // acc[field.key] = templateTypeToSchema[field.type]
    return acc
  }, {})

  schema.value = schema.value.extend({
    dataFields: z.object(dataFields),
    name: z.string().min(3).max(255).default(template.name),
    template_id: z.string().default(id),
    project_id: z.string().default(projectId.value),
    color: z.string().default(template.color),
    shared: z.boolean().default(template.shared),
  })

  selectedTemplateId.value = id
}

watch(projectId, (id) => {
  if (id) {
    handleProjectChange(id)
  }
})

onMounted(() => {
  if (projectId.value) {
    handleProjectChange(projectId.value)
  }
})

onBeforeUnmount(() => {
  store.commit('geo/SET_REPORT_TEMPLATES', null)
})
</script>

<template>
  <Dialog :open="isOpen" @update:open="isOpen = $event">
    <DialogContent class="max-w-[350px] max-h-[80vh] overflow-auto">
      <DialogHeader>
        <DialogTitle>Add geometry</DialogTitle>
        <DialogDescription></DialogDescription>
      </DialogHeader>

      <Form
        v-slot="{ getValues }"
        class="space-y-3"
        @submit="onSubmit"
        :validation-schema="toTypedSchema(schema)"
        :key="selectedTemplateId || projectId || selectedProjectId"
      >
        <FormField v-if="!projectId" v-slot="{ value, componentField }" name="project_id">
          <FormItem>
            <FormLabel>Project</FormLabel>
            <FormControl>
              <Select v-bind="componentField" @update:model-value="() => handleProjectChange(getValues().project_id)">
                <SelectTrigger>
                  <SelectValue placeholder="Select a project" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem
                      v-for="(p, index) of projects"
                      :key="index"
                      :value="`${p.project_id}`"
                    >
                      {{p.project_name}}
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
        <FormField v-else v-slot="{ value, componentField }" name="project_id">
          <FormItem>
            <FormLabel>Project</FormLabel>
            <FormControl>
              {{ projects?.find(p => p.project_id === parseInt(projectId))?.project_name }}
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-if="selectedProjectId && templates?.length" v-slot="{ componentField }" name="template_id">
          <FormItem>
            <FormLabel>Template</FormLabel>
            <FormControl>
              <Select v-bind="componentField" @update:model-value="handleTemplateSelect(getValues().template_id)">
                <SelectTrigger>
                  <SelectValue placeholder="Select a type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem
                      v-for="(t, index) of templates"
                      :key="index"
                      :value="t.id.toString()"
                    >
                      {{t.name}}
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <div class="flex space-x-3">
          <div class="basis-5/6">
            <FormField v-slot="{ componentField }" name="name">
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input v-bind="componentField" :disabled="selectedTemplateId ? true : false" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
          </div>
          <div class="basis-1/6">
            <FormField v-slot="{ componentField }" name="color">
              <FormItem>
                <FormLabel>Color</FormLabel>
                <FormControl>
                  <div class="pt-1">
                    <label for="colorPicker" :style="`background-color: ${componentField.modelValue}`" class="flex w-8 h-8 rounded-full">
                      <input id="colorPicker" class="mr-1 w-full h-full invisible" type="color" :value="componentField.modelValue" v-bind="componentField" :disabled="selectedTemplateId ? true : false" />
                    </label>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
          </div>
        </div>

        <FormField v-slot="{ componentField }" name="description">
          <FormItem>
            <FormLabel>Description</FormLabel>
            <FormControl>
              <Input v-bind="componentField" />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <div v-if="selectedProjectId && templates?.length && schema.shape?.dataFields" class="space-y-3 pb-4">
          <h4 class="font-bold">Collected data</h4>

          <FormField
            v-for="(key, index) in Object.entries(schema.shape.dataFields.shape)"
            v-slot="{ componentField, value, handleChange }"
            :key="index" :name="'dataFields.' + key[0]"
          >
            <FormItem>
              <FormLabel v-if="key[1].description.type !== 2">{{ key[0] }}</FormLabel>
              <FormControl>
                <Input v-if="key[1].description.type === 0" v-bind="componentField" type="text" />
                <Input v-if="key[1].description.type === 1" v-bind="componentField" type="number" />
                <div v-if="key[1].description.type === 2" class="flex items-center">
                  <Switch
                    :checked="value"
                    @update:checked="handleChange"
                  />
                  <Label class="ml-2">{{ key[0] }}</Label>
                </div>
                <Textarea v-if="key[1].description.type === 3" v-bind="componentField" />
                <RadioGroup v-if="key[1].description.type === 4" v-bind="componentField">
                  <FormItem v-for="value in key[1]._def.values" class="flex items-center space-y-0 gap-x-3">
                    <FormControl>
                      <RadioGroupItem :value="value" />
                    </FormControl>
                    <FormLabel class="font-normal">{{ value }}</FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
        </div>

        <FormField v-slot="{ value, handleChange }" name="shared">
          <FormItem>
            <FormControl>
              <div class="flex items-center">
                <Switch
                  :checked="value"
                  @update:checked="handleChange"
                />
                <Label class="ml-2">Shared</Label>
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField
          v-slot="{ value, handleChange }"
          name="geofence"
          v-if="geojson.geometry.type === 'Polygon'"
        >
          <FormItem>
            <FormControl>
              <div class="flex items-center">
                <Switch
                  :checked="value"
                  @update:checked="handleChange"
                />
                <Label class="ml-2">Virtual Geofence</Label>
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <div class="p-2 space-y-3 border rounded border-gray-200">
          <FormField
            v-if="getValues().geofence"
            v-slot="{ componentField }"
            name="geofenceEvents"
          >
            <FormItem>
              <FormControl>
                <RadioGroup v-bind="componentField" class="flex space-x-3">
                  <FormItem class="flex items-center space-y-0 gap-x-3">
                    <FormControl>
                      <RadioGroupItem value="in" />
                    </FormControl>
                    <FormLabel class="font-normal">In</FormLabel>
                  </FormItem>
                  <FormItem class="flex items-center space-y-0 gap-x-3">
                    <FormControl>
                      <RadioGroupItem value="out" />
                    </FormControl>
                    <FormLabel class="font-normal">Out</FormLabel>
                  </FormItem>
                  <FormItem class="flex items-center space-y-0 gap-x-3">
                    <FormControl>
                      <RadioGroupItem value="both" />
                    </FormControl>
                    <FormLabel class="font-normal">Both</FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
            </FormItem>
            <FormMessage />
          </FormField>

          <FormField
            v-slot="{ value, handleChange }"
            name="geofencePrompt"
            v-if="getValues().geofence && geojson.geometry.type === 'Polygon'"
          >
            <FormItem>
              <FormControl>
                <div class="flex items-center">
                  <Switch
                    :checked="value"
                    @update:checked="handleChange"
                  />
                  <Label class="ml-2">Prompt user on crossing</Label>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
        </div>


        <DialogFooter>
          <Button variant="ghost" @click="close" type="button">Close</Button>
          <Button type="submit">Save</Button>
        </DialogFooter>
      </Form>
    </DialogContent>
  </Dialog>
</template>
