<script setup>
import { computed, watch, onMounted, watchEffect, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {
  ChevronLeft,
  BarChart3,
  Puzzle,
  Users,
  NotepadTextDashed,
  Settings
} from 'lucide-vue-next'
import {Button} from '@/components/ui/button'
import {Label} from '@/components/ui/label'
import {BackButton} from '@/components/buttons'
import { useCheckProjectAccess } from '@/composables/useCheckAccess'

const route = useRoute()
const router = useRouter()

const store = useStore()
const project = computed(() => store.getters['project/current'])
const projectId = computed(() => parseInt(route.params.project_id))
const hideLayout = computed(() => route.name === 'ProjectEdit' || route.name === 'ProjectCreate' || route.name === 'ProjectHome')

const resetSelected = () => {
  store.commit('project/SET_CURRENT', null)
  store.commit('search/REMOVE_PARAM', { key: 'project_id' })
  open.value = false
}

onMounted(async () => {
  await store.dispatch('project/fetchProject', projectId.value)
})

onBeforeUnmount(() => {
  resetSelected()
})

watch(() => project.value, (value) => {
  if (value) {
    store.commit('project/SET_LAST_SELECTED', value)
  }
})

watchEffect(async () => {
  await store.dispatch('project/fetchProject', projectId.value)
})
</script>

<template>
  <div v-if="!hideLayout">
    <div :key="projectId" class="flex items-center justify-between px-3 pt-3">
      <div class="basis-1/2 flex items-center">
        <div>
          <Label class="text-xs h-3 text-gray-500">Project Settings</Label>
          <h2 class="text-lg font-bold">{{project?.project_name}}</h2>
        </div>
      </div>
      <div class="basis-1/2 flex items-center justify-end space-x-2">
        <!-- <router-link :to="{ name: 'ProjectHome', params: { project_id: projectId }} ">
          <Button size="sm" variant="outline" class="w-10 h-10 text-gray-500" :class="{'text-primary': route.name === 'ProjectHome'}">
            <BarChart3 />
          </Button>
        </router-link>
   -->
        <!-- <router-link :to="{ name: 'ProjectComponents', params: { project_id: projectId }} ">
          <Button size="sm" variant="outline" class="w-10 h-10 text-gray-500" :class="{'text-primary': route.name === 'ProjectComponents'}">
            <Puzzle />
          </Button>
        </router-link>
   -->
        <router-link :to="{ name: 'ProjectMembers', params: { project_id: projectId }} ">
          <Button size="sm" :variant="route.name === 'ProjectMembers' ? 'default' : 'secondary' " class="h-8">
            <Users class="w-4 h-4 mr-2" />
            Members
          </Button>
        </router-link>

        <router-link :to="{ name: 'ProjectTemplates', params: { project_id: projectId }} ">
          <Button size="sm" :variant="route.name === 'ProjectTemplates' ? 'default' : 'secondary' " class="h-8">
            <NotepadTextDashed class="w-4 h-4 mr-2" />
            Templates
          </Button>
        </router-link>

        <BackButton class="mr-2" />
        <!-- <router-link :to="{ name: 'ProjectSettings', params: { project_id: projectId }} " v-if="project && useCheckProjectAccess(project, 'project_settings', ['update'])">
          <Button size="sm" :variant="route.name === 'ProjectSettings' ? 'default' : 'secondary' " class="h-8">
            <Settings class="w-4 h-4 mr-2" />
            Settings
          </Button>
        </router-link> -->
      </div>
    </div>
    <div class="p-3" :key="projectId">
      <router-view></router-view>
    </div>
  </div>
  <router-view v-else></router-view>
</template>
