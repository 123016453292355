<script setup>
import {ref, computed, watch, onMounted} from 'vue'
import {useStore} from 'vuex'
import {useRoute, useRouter} from 'vue-router'
import axios from 'axios'
import {Plus, Trash2, X, FolderDot, Undo2, Eye} from 'lucide-vue-next'
import {debounce} from 'lodash'

import {Input} from '@/components/ui/input'
import {Button} from '@/components/ui/button'
import {Label} from '@/components/ui/label'
import {FormFeature} from '@/components/forms'
import {Point, LineString, Polygon} from '@/components/icons'
import {Spinner} from '@/components/ui/spinner'
import {MapFileInput} from '@/components/map'

const props = defineProps({
  drawedFeatures: {
    type: Array,
    required: true,
  },
})

const emit = defineEmits(['deleteFeature', 'focusOnFeature', 'startDraw', 'previewFeature', 'resetDrawing'])

const router = useRouter()
const route = useRoute()
const projectId = computed(() => parseInt(route.params.project_id))

const store = useStore()
const templates = computed(() => store.getters['geo/getProjectReportTemplates'])

const activeAction = ref(null)

const isOpen = ref(false)
const selectedGeometry = ref(null)
const isSearching = ref(false)
const searchText = ref('')
const searchFeatures = ref([])
const features = computed(() => [...searchFeatures.value, ...props.drawedFeatures])

const templateActions = computed(() => {
  if (!templates.value) {
    return []
  }
  return templates.value.map((template) => {
    if (!template.enabled) {
      return
    }
    return {
      name: template.name,
      type: template.geometry_type === 'Point' ? 'draw_point' : template.geometry_type === 'LineString' ? 'draw_line_string' : 'draw_polygon',
      color: template.color,
      s3_icon: template.s3_icon,
      template_id: template.id,
    }
  })
})

const defaultActions = [
  {name: 'Point', type: 'draw_point', color: '0059ff', icon: 'point'},
  {name: 'Line', type: 'draw_line_string', color: '0059ff', icon: 'line-string'},
  {name: 'Polygon', type: 'draw_polygon', color: '0059ff', icon: 'polygon'},
]

const actions = computed(() => [...defaultActions, ...templateActions.value])

const toggle = () => {
  isOpen.value = !isOpen.value
}

const selectGeometry = (feature) => {
  if (!feature) {
    selectedGeometry.value = null
    return
  }
  selectedGeometry.value = feature
}

const showFeature = (feature) => {
  if (feature.properties.isSearch) {
    previewFeature(feature)
  } else {
    focusOnFeature(feature)
  }
}

const deleteFeature = (feature) => {
  emit('deleteFeature', feature)
}

const focusOnFeature = (feature) => {
  emit('focusOnFeature', feature)
}

const previewFeature = (feature) => {
  emit('previewFeature', feature)
}

const resetSearch = () => {
  searchFeatures.value = []
  previewFeature(null)
  selectedGeometry.value = null
}

const reset = () => {
  activeAction.value = null
  searchText.value = ''
  emit('resetDrawing')
  resetSearch()
}

const startDraw = (action) => {
  reset()
  activeAction.value = action
  emit('startDraw', action.type)
}

const searchLocation = async (value) => {
  resetSearch()
  try {
    const response = await axios.get('http://nominatim.openstreetmap.org/search', {
      params: {
        q: value,
        polygon_geojson: 1,
        format: 'json',
        limit: 6
      }
    })

    const results = response.data

    if (results.length === 0) {
      return
    }

    for (const result of results) {
      const feature = {
        type: 'Feature',
        geometry: result.geojson,
        properties: {
          isSearch: true,
          name: result.display_name || result.name,
          addresstype: result.addresstype,
        }
      }

      searchFeatures.value.push(feature)
    }
  } catch (error) {
    console.error(error)
  }
}

const search = debounce(async () => {
  isSearching.value = true
  await searchLocation(searchText.value)
  isSearching.value = false
}, 1000)

const close = () => {
  isOpen.value = false
  reset()
}

onMounted(() => {
  if (projectId.value) {
    store.dispatch('geo/fetchProjectReportTemplates', projectId.value)
  }
})

watch(() => projectId.value, async (value) => {
  if (value) {
    await store.dispatch('geo/fetchProjectReportTemplates', value)
  } else {
    store.commit('geo/SET_REPORT_TEMPLATES', [])
  }
})

watch(() => features.value, (value) => {
  if (value.length === 0) {
    selectGeometry(null)
  }

  if (value.length > 0 && selectedGeometry.value === null) {
    selectGeometry(value[0])
  }
})

watch(() => searchText.value, async (value) => {
  if (searchText.value.length === 0) {
    resetSearch()
    return
  }

  await search()
})
</script>

<template>
  <div>
    <Transition name="slideToolbox">
      <div v-if="isOpen" class="bg-white rounded-lg shadow-lg max-h-[70vh] overflow-y-auto mb-2">
        <div class="border-b border-gray-200 last:border-0">
          <div class="flex flex-wrap justify-start max-w-[285px] min-w-[285px] overflow-hidden">
            <div class="basis-1/5 w-9 flex flex-col items-center my-2">
              <MapFileInput @change="() => close()" />
            </div>
            <div v-for="action of actions" class="basis-1/5 w-9 flex flex-col items-center my-2" @click="startDraw(action)">
              <Button class="w-9 h-9 rounded-full p-0 relative" :class="{'outline outline-2 outline-black': activeAction && action.name === activeAction.name}" :style="{backgroundColor: action.color}">
                <Plus
                  class="w-3 h-3 absolute top-0 left-0 bg-secondary border rounded-full"
                  :style="{color: action.color, borderColor: action.color}"
                />
                <FolderDot class="w-4 h-4" v-if="action.icon === 'folder-dot'" />
                <Point class="fill-white" v-else-if="action.icon === 'point'" />
                <LineString class="fill-white" v-else-if="action.icon === 'line-string'" />
                <Polygon class="fill-white" v-else-if="action.icon === 'polygon'" />
                <div v-else-if="!action.icon && action.s3_icon" class="w-9 h-9 flex items-center justify-center">
                  <img class="object-contain w-5 h-5" :src="action.s3_icon" alt="">
                </div>
              </Button>
              <Label class="text-center mt-1" style="line-height: 1;" :class="{'font-bold': activeAction && action.name === activeAction.name}">{{ action.name }}</Label>
            </div>

            <div v-if="activeAction" class="basis-1/5 w-9 flex flex-col items-center my-2" @click="reset()">
              <Button class="w-9 h-9 rounded-full p-0 relative bg-gray-100 text-black">
                <Undo2 class="w-4 h-4" />
              </Button>
              <Label class="text-center">Reset</Label>
            </div>
          </div>
        </div>

        <div v-if="activeAction" class="relative px-2 py-2 min-w-[300px]">
          <Input
            placeholder="Search for country, city or place"
            v-model="searchText"
          />
          <Button
            v-if="searchText.length > 0 && !isSearching"
            variant="ghost"
            class="p-0 w-6 h-6 absolute top-4 right-4"
            @click="searchText = ''"
          >
            <X class="w-4 h-4 text-gray-500" />
          </Button>
          <Spinner v-if="isSearching" class="w-4 h-4 absolute top-3.5 right-2" />
        </div>

        <div v-if="activeAction && features.length > 0" class="px-2 pb-2">
          <div class="bg-white border border-gray-200 rounded-md overflow-hidden">
            <div
              v-for="(feature, index) in features"
              :key="feature.id"
              class=" border-gray-200 border-b last:border-b-0 cursor-pointer"
              :class="{'bg-secondary': selectedGeometry === feature}"
            >
              <div class="flex justify-between">
                <div @click="selectGeometry(feature)" class="w-full px-2 py-1">
                  <div class="flex items-center justify-between">
                    <h4 class="text-sm text-gray-600 mb-0.5" :class="{'font-semibold' : selectedGeometry === feature}">
                      <span v-if="!feature.properties.name">Drawed ({{ index + 1 }})</span>
                      <span v-else>{{ feature.properties.name }}</span>
                    </h4>
                  </div>
                  <ul class=" text-gray-400 text-xs">
                    <li v-if="feature.properties.addresstype" class="capitalize">Type: {{ feature.properties.addresstype }}</li>
                    <li>Geometry: {{ feature.geometry.type }}</li>
                    <li v-if="feature.properties.areaText">Size: {{ feature.properties.areaText }}</li>
                  </ul>
                </div>
                <div class="flex p-1">
                  <Button size="sm" variant="ghost" class="w-6 h-6 p-1" @click="showFeature(feature)">
                    <Eye class="text-gray-600" />
                  </Button>
                  <Button v-if="!feature.properties.isSearch" @click="deleteFeature(feature)" size="sm" variant="ghost" class="w-6 h-6 p-1">
                    <Trash2 class="text-red-400" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeAction && features.length === 0" class="pb-2">
          <p class="text-gray-500 text-center py-2">Start drawing or search</p>
        </div>

        <div v-if="activeAction && features.length > 0" class="p-2 border-t border-gray-200">
          <div>
            <FormFeature
              v-if="selectedGeometry"
              :feature="selectedGeometry"
              :template-id="activeAction.template_id ? activeAction.template_id.toString() : null"
              @update:created="close()"
            />
          </div>
        </div>
      </div>
    </Transition>
    <div class="flex items-center justify-end">
      <Button @click="toggle" class="rounded-full shadow-md h-14 w-14 p-0">
        <Plus :class="{'rotate-45': isOpen}" class="w-8 h-8 transition duration-150 ease-out" />
        <!-- <span class="ml-2 font-bold">Data</span> -->
      </Button>
    </div>
  </div>
</template>

<style>
.slideToolbox-enter-active, .slideToolbox-leave-active {
  transition: transform 0.1s ease-in-out, opacity 0.1s ease-in-out;
}
.slideToolbox-enter-from, .slideToolbox-leave-to {
  transform: translateY(10%);
  opacity: 0;
}
</style>
