<script setup>
import {computed, onBeforeMount, onBeforeUnmount, watch} from 'vue'
import {useStore} from 'vuex'

import FilteringPoints from './FilteringPoints.vue'

const store = useStore()
const member = computed(() => store.getters['user/getMember'])
const contextFormFeature = computed(() => store.getters['geo/getContextFormFeature'])

const props = defineProps({
  feature: Object,
  map: Object
})

const originCollection = computed(() => {
  return {
    type: 'FeatureCollection',
    features: [props.feature]
  }
})

const createLayers = () => {
  const featureType = props.feature.geometry.type

  if (featureType === 'Point') {
    props.map.addSource('page-report-point', {
      type: 'geojson',
      data: originCollection.value,
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 50
    })

    props.map.addLayer({
      id: 'page-unclustered-point',
      type: 'circle',
      source: 'page-report-point',
      paint: {
        'circle-color': ['get', 'color'],
        'circle-radius': 10,
        'circle-stroke-width': 3,
        'circle-stroke-color': '#ffffff'
      }
    })
  } else {
    props.map.addSource('page-report-mixed', {
      type: 'geojson',
      data: originCollection.value,
      'generateId': true
    })

    props.map.addLayer({
      'id': 'page-report-fill',
      'type': 'fill',
      'source': 'page-report-mixed',
      'paint': {
        'fill-color': ['get', 'color'],
        'fill-opacity': 0.02
      },
      'filter': ['==', '$type', 'Polygon']
    })

    props.map.addLayer({
      'id': 'page-report-lines',
      'type': 'line',
      'source': 'page-report-mixed',
      'paint': {
        'line-color': ['get', 'color'],
        'line-width': 6
      },
      'filter': ['any', ['==', '$type', 'LineString'], ['==', '$type', 'Polygon']]
    })
  }
}

const cleanup = () => {
  if (props.map.getLayer('page-report-fill')) {
    props.map.removeLayer('page-report-fill')
  }

  if (props.map.getLayer('page-report-lines')) {
    props.map.removeLayer('page-report-lines')
  }

  if (props.map.getLayer('page-unclustered-point')) {
    props.map.removeLayer('page-unclustered-point')
  }

  if (props.map.getSource('page-report-point')) {
    props.map.removeSource('page-report-point')
  }

  if (props.map.getSource('page-report-mixed')) {
    props.map.removeSource('page-report-mixed')
  }
}

onBeforeMount(async () => {
  createLayers()
})

onBeforeUnmount(() => {
  cleanup()
})

watch(() => contextFormFeature.value, async (feature) => {
  if (feature) {
    cleanup()
  } else {
    createLayers()
  }
})
</script>

<template>
  <FilteringPoints
    v-if="feature.geometry.type === 'LineString' && member?.is_super_user"
    :key="feature.properties.code"
    :feature="feature"
    :map="map"
  />
</template>
