<script setup lang="ts">
import {computed} from 'vue'
import {useRouter} from 'vue-router'

const router = useRouter()
const route = router.currentRoute

const isBig = computed(() => route.value.meta.layout === 'float-big')
</script>

<template>
  <div
    v-if="route.meta.layout !== 'hide'"
    class="flex-1 overflow-y-auto text-sm"
    :class="{'min-w-[700px] max-w-[700px]': isBig, 'min-w-[340px] max-w-[340px]': !isBig}"
  >
    <router-view />
  </div>
</template>
