<script setup>
import {computed, onBeforeMount, onBeforeUnmount, onMounted, ref, watchEffect, watch} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {kml} from '@tmcw/togeojson'
import ExifReader from 'exifreader'
import { v4 as uuidv4 } from 'uuid'
import {bbox} from '@turf/turf'
import JSZip from 'jszip'

import {ChevronLeft, Trash2, Eye} from 'lucide-vue-next'
import {useToast} from '@/components/ui/toast/use-toast'
import {Button} from '@/components/ui/button'
import {BackButton} from '@/components/buttons'
import {FileViewerFeature} from '@/pages/FileViewer'

const { toast } = useToast()
const router = useRouter()
const store = useStore()
const map = computed(() => store.getters['geo/getMap'])
const loadedFiles = computed(() => store.getters['geo/getFiles'])

const MAX_FILES = 30
const MAX_SHAPES = 30

const isSelectedFile = ref(false)
const isUploading = ref(false)
const collection = ref({
  type: 'FeatureCollection',
  features: []
})

const activeLayers = []
const activeSources = []

function parseDate(s) {
  const b = s.split(/\D/)
  return new Date(b[0], b[1]-1, b[2], b[3], b[4], b[5])
}

function parseXML(data) {
  try {
    const cleanData = data.replace(/xsi:schemaLocation=["'][^"']*["']\s*/g, '')

    const parser = new DOMParser()
    return parser.parseFromString(cleanData, 'application/xml')
  } catch (e) {
    console.error('Error parsing XML data:', e)
    return null
  }
}

async function readFile(file, readAsBuffer = false) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (event) => {
      resolve(event.target.result)
    }

    reader.onerror = (event) => {
      reject(event.target.error)
    }

    if (readAsBuffer) {
      return reader.readAsText(file)
    }
    return reader.readAsArrayBuffer(file)
  })
}

const setCollection = (fileCollection, fileName) => {
  createLayers()

  for (let feature of fileCollection.features) {
    feature.properties.fileName = fileName
    feature.properties.name = fileName.split('.').shift()
    feature.properties.local_uuid = uuidv4()
    feature.properties.isEditing = false
    collection.value.features.push(feature)
  }

  const sourceNoActiveFeature = collection.value.features.filter(f => !f.properties.isEditing)
  map.value.getSource('temp_data').setData({
    type: 'FeatureCollection',
    features: sourceNoActiveFeature
  })
}

const splitFeature = (feature) => {
  collection.value.features = collection.value.features.filter(f => f.properties.local_uuid !== feature.properties.local_uuid)

  if (feature.geometry?.type === 'GeometryCollection') {
    for (let geometry of feature.geometry.geometries) {
      const geoFeature = {
        type: 'Feature',
        geometry: geometry,
        properties: {
          ...feature.properties ? feature.properties : {},
          fileName: feature.properties.fileName,
          name: feature.properties.fileName.split('.').shift(),
          local_uuid: uuidv4(),
          isEditing: false
        }
      }
      collection.value.features.push(geoFeature)
    }
  }
}

const parseKML = async (file) => {
  const contents = await readFile(file, true)
  const xml = parseXML(contents)

  try {
    const featureCollection = kml(xml)
    const features = featureCollection.features.map(feature => {
      feature.properties = {
        name: feature.properties?.name ? feature.properties.name : file.name
      }
      return feature
    })
    const cleanedCollection = {
      type: 'FeatureCollection',
      features: features
    }
    setCollection(cleanedCollection, file.name)
  } catch (error) {
    console.error("Error parsing .kml file:", error)
  }
}

const parseKMZ = async (file) => {
  try {
    const zip = new JSZip()
    const archive = await zip.loadAsync(file)
    const kmlFiles = Object.keys(archive.files).filter(f => f.endsWith('.kml'))

    for (let file of kmlFiles) {
      const kmlFile = archive.files[file]
      const kmlContents = await kmlFile.async('string')
      const xml = parseXML(kmlContents)

        const featureCollection = kml(xml)
        const features = featureCollection.features.map(feature => {
          feature.properties = {
            name: feature.properties?.name ? feature.properties.name : file
          }
          return feature
        })
        const cleanedCollection = {
          type: 'FeatureCollection',
          features: features
        }
        setCollection(cleanedCollection, file)
    }
  } catch (error) {
    console.error("Error parsing .kml file:", error)
  }
}

const parseGeoJSON = async (file) => {
  const contents = await readFile(file, true)
  try {
    const featureCollection = JSON.parse(contents)
    const features = featureCollection.features.map(feature => {
      feature.properties = {
        name: feature.properties?.name ? feature.properties.name : file.name
      }
      return feature
    })

    const cleanedCollection = {
      type: 'FeatureCollection',
      features: features
    }
    setCollection(cleanedCollection, file.name)
  } catch (error) {
    console.error("Error parsing .geojson file:", error)
  }
}

const getImageGeometry = (gps) => {
  let latitude = gps.Latitude;
  let longitude = gps.Longitude;

  latitude = Math.max(-90, Math.min(90, latitude));
  longitude = Math.max(-180, Math.min(180, longitude));

  const geometry = {
    type: 'Point',
    coordinates: [
      longitude,
      latitude
    ]
  }

  return geometry
}

const parseImage = async (file) => {
  try {
    const tags = await ExifReader.load(file, {
      expanded: true
    })
    const created_origin = tags.DateTimeOriginal ? parseDate(tags.DateTimeOriginal.value[0]).getTime() : Date.now()

    console.log(tags)

    const geometry = tags.gps ? getImageGeometry(tags.gps): null

    const feature = {
      type: 'Feature',
      properties: {
        name: file.name,
        created_origin: created_origin,
        type: 'Point',
        resource: file
      },
      geometry: geometry
    }

    const featureCollection = {
      type: 'FeatureCollection',
      features: [feature]
    }

    setCollection(featureCollection, file.name)
  } catch (error) {
    console.error("Error parsing image file:", error)
  }
}

const handleFile = async (file) => {
  const ext = file.name.split('.').pop().toLowerCase()

  switch (ext) {
    case 'kml':
      await parseKML(file)
      break
    case 'kmz':
      await parseKMZ(file)
      break
    case 'geojson':
      await parseGeoJSON(file)
      break
    case 'jpg':
      await parseImage(file)
      break
    case 'jpeg':
      await parseImage(file)
      break
    case 'png':
      await parseImage(file)
      break
    default:
      toast({title: 'Invalid file type', status: 'error'})
      break
  }
}

const handleFiles = async (files) => {
  if (!files) return
  if (files.length === 0) return

  if (files.length > MAX_FILES) {
    toast({title: `Only ${MAX_FILES} files can be uploaded at once`})
    return
  }

  for (let i = 0; i < files.length; i++) {
    if (i > MAX_FILES) break
    await handleFile(files[i])
  }
}

const createLayers = () => {
  if (!map.value.getSource('temp_data')) {
    map.value.addSource('temp_data', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      },
    });
    activeLayers.push('temp_data');
  }

  if (!map.value.getLayer('temp-geometry-layer-fill')) {
    map.value.addLayer({
      'id': 'temp-geometry-layer-fill',
      'type': 'line',
      'source': 'temp_data',
      'paint': {
        'line-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
        'line-width': 4
      },
      'filter': ['any', ['==', '$type', 'LineString'], ['==', '$type', 'Polygon']]
    });
    activeLayers.push('temp-geometry-layer-fill');
  }

  if (!map.value.getLayer('temp-geometry-layer-lines')) {
    map.value.addLayer({
      'id': 'temp-geometry-layer-lines',
      'type': 'fill',
      'source': 'temp_data',
      'paint': {
        'fill-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
        'fill-opacity': 0.03
      },
      'filter': ['==', '$type', 'Polygon']
    });
    activeLayers.push('temp-geometry-layer-lines');
  }

  if (!map.value.getLayer('temp-geometry-layer-points')) {
    map.value.addLayer({
      id: 'temp-geometry-layer-points',
      type: 'circle',
      source: 'temp_data',
      paint: {
        'circle-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
        'circle-radius': 8,
        'circle-stroke-width': 2,
        'circle-stroke-color': '#fff'
      },
      'filter': ['==', '$type', 'Point']
    });
    activeLayers.push('temp-geometry-layer-points');
  }

  map.value.getSource('temp_data').setData(collection.value);
  activeSources.push('temp_data');
};

const focusCollection = () => {
  const bounds = bbox(collection.value)
  map.value.fitBounds(bounds, { padding: 20 })
}

const removeFeature = (feature) => {
  collection.value.features = collection.value.features.filter(f => f !== feature)
  map.value.getSource('temp_data').setData(collection.value)

  if (collection.value.features.length === 0) {

    if (feature.properties?.code) {
      // router.push({name: 'ProjectHome', params: {project_id: feature.properties.project_id}})
      router.push({name: 'Feature', params: {project_id: feature.properties.project_id, featureCode: feature.properties.code}})
    }
  }
}

const clearCollection = () => {
  collection.value.features = []
  map.value.getSource('temp_data').setData(collection.value)
}

const startEditing = (feature) => {
  for (let f of collection.value.features) {
    if (f.properties.local_uuid === feature.properties.local_uuid) {
      f.properties.isEditing = !f.properties.isEditing
    } else {
      f.properties.isEditing = false
    }
  }
  const collectionNoActive = {
    type: 'FeatureCollection',
    features: collection.value.features.filter(f => !f.properties.isEditing)
  }
  map.value.getSource('temp_data').setData(collectionNoActive)
}

const stopEditing = (feature) => {
  for (let f of collection.value.features) {
    if (f.properties.local_uuid === feature.properties.local_uuid) {
      f.geomertry = feature.geometry
      f.properties.isEditing = false
    }
  }

  map.value.getSource('temp_data').setData(collection.value)
}

const updateFeature = (feature) => {
  for (let f of collection.value.features) {
    if (f.properties.local_uuid === feature.properties.local_uuid) {
      f.properties = feature.properties
    }
  }

  map.value.getSource('temp_data').setData(collection.value)
}

const reset = () => {
  for (let layer of activeLayers) {
    if (map.value.getLayer(layer)) map.value.removeLayer(layer)
  }

  for (let source of activeSources) {
    if (map.value.getSource(source)) map.value.removeSource(source)
  }

  collection.value.features = []
  store.commit('geo/SET_FILES', null)
}

const uploadAll = async () => {
  isUploading.value = true
  for (let feature of collection.value.features) {
    feature.properties.isUpload = true
  }

  setTimeout(() => {
    for (let feature of collection.value.features) {
      feature.properties.isUpload = false
    }

    isUploading.value = false
  }, 1000)
}

watchEffect(async () => {
  await handleFiles(loadedFiles.value)
})

onBeforeUnmount(() => {
  reset()
})
</script>

<template>
  <div class="bg-white p-3">
    <div>
      <div class="mb-2 flex items-center justify-between">
        <div class="flex items-center">
          <BackButton class="mr-2" />
          <h2 class="font-bold">Shapes: {{ collection.features.length }}</h2>
        </div>
        <div class="flex items-center space-x-1">
          <Button v-if="collection.features.length" @click="focusCollection" size="sm" variant="secondary" class=" h-8">
            <Eye class="text-gray-500 w-4 h-4" />
            <span class="ml-2 text-gray-700">Zoom</span>
          </Button>
          <Button v-if="collection.features.length" @click="clearCollection" size="sm" variant="secondary" class=" h-8">
            <Trash2 class="text-gray-500 w-4 h-4" />
            <span class="ml-2 text-gray-700">Clear</span>
          </Button>
        </div>
      </div>

      <div class="flex justify-end mb-3" v-if="collection.features.length > 0">
        <Button @click="uploadAll" :disable="isUploading" size="sm">Upload all</Button>
      </div>

      <div class="mb-6" :key="collection.features.length">
        <FileViewerFeature
          v-for="(feature, index) in collection.features"
          :feature="feature"
          :index="index"
          :key="feature.properties.local_uuid"
          @removeFeature="(feature) => removeFeature(feature)"
          @startEditing="(feature) => startEditing(feature)"
          @stopEditing="(feature) => stopEditing(feature)"
          @updateFeature="(feature) => updateFeature(feature)"
          @split="(feature) => splitFeature(feature)"
        />
      </div>
    </div>
    <div v-if="collection.features.length === 0">
      <div xs12 class="flex items-center justify-center p-3">
        <div class="text-center">
          <h2 class="text-lg font-bold">No shapes loaded</h2>
          <p class="text-gray-500">Drag and drop or add in '+' button</p>
        </div>
      </div>
    </div>
  </div>
</template>
