<script setup>
import {ref, computed, onBeforeMount} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {useStore} from 'vuex'

import {PlusCircle} from 'lucide-vue-next'
import MembersList from './MembersList.vue'
import InvitesList from './InvitesList.vue'
import {Button} from '@/components/ui/button'
import {DialogEditMember} from '@/components/dialogs'
import { useCheckProjectAccess } from '@/composables/useCheckAccess'


const store = useStore()
const route = useRoute()
const router = useRouter()

const projectId = computed(() => parseInt(route.params.project_id))
const project = computed(() => store.getters['project/current'])
const members = computed(() => store.getters['project/getMembers'])
const invites = computed(() => store.getters['project/getInvites'])
const notAcceptedInvites = computed(() => invites.value.filter(invite => !invite.is_accepted))

const membersLength = computed(() => members.value.length)
const invitesLength = computed(() => notAcceptedInvites.value.length)

const dialogInviteMember = ref(null)

console.log(123)

onBeforeMount(async () => {
  await store.dispatch('project/fetchProject', projectId.value)
  await store.dispatch('project/fetchMembers', projectId.value)

  if (useCheckProjectAccess(project.value, 'project_invite', ['read'])) {
    await store.dispatch('project/fetchInvites', projectId.value)
  }
})
</script>

<template>
  <div :key="projectId">
    <DialogEditMember ref="dialogInviteMember" />

    <div class="mb-1 flex items-center">
      <div class="basis-1/2">
        <h3 class="text-base font-bold">Members ({{membersLength}})</h3>
      </div>
      <div class="basis-1/2 flex justify-end">
        <Button
          class="px-2 text-gray-500"
          variant="ghost"
          v-if="useCheckProjectAccess(project, 'project_invite', ['create'])"
          @click="dialogInviteMember.open()"
        ><PlusCircle /></Button>
      </div>
    </div>

    <div>
      <MembersList v-if="membersLength" :members="members" />
    </div>

    <div class="mt-6 mb-2 flex items-center">
      <div class="basis-1/2">
        <h3 class="text-base font-bold">Pending invites ({{invitesLength}})</h3>
      </div>
    </div>
    <InvitesList v-if="invitesLength" :invites="notAcceptedInvites" :key="invitesLength" />
  </div>
</template>
