<script setup>
  import { computed, onMounted, ref, onUnmounted } from 'vue'
  import { useStore } from 'vuex'
  import Chart from 'chart.js/auto'
  import { API, HOST_TULUM } from '@/utils/http'
  import {bbox} from '@turf/turf'
  import {Switch} from '@/components/ui/switch'
  import {Spinner} from '@/components/ui/spinner'

  const store = useStore()
  const map = computed(() => store.getters['geo/getMap'])
  const imagesLoaded = ref(false)
  const showImages = ref(false)
  const isChartRendered = ref(false)

  const viridis = [
    {index: 0, rgb: [253, 231, 37]},
    {index: 0.1, rgb: [181, 222, 43]},
    {index: 0.2, rgb: [110, 206, 88]},
    {index: 0.3, rgb: [53, 183, 121]},
    {index: 0.4, rgb: [31, 158, 137]},
    {index: 0.5, rgb: [38, 130, 142]},
    {index: 0.6, rgb: [49, 104, 142]},
    {index: 0.7, rgb: [62, 73, 137]},
    {index: 0.8, rgb: [72, 40, 120]},
    {index: 0.9, rgb: [68, 1, 84]},
  ]

  const ylgn = [
    // {index: 0, rgb: [0, 68, 28]},
    {index: 0, rgb: [0, 109, 44]},
    {index: 0.1, rgb: [35, 139, 69]},
    {index: 0.2, rgb: [65, 171, 89]},
    {index: 0.3, rgb: [120, 198, 121]},
    {index: 0.4, rgb: [173, 221, 142]},
    {index: 0.5, rgb: [217, 239, 163]},
    {index: 0.6, rgb: [247, 252, 185]},
    {index: 0.7, rgb: [255, 255, 229]},
  ]

  const greens = [
    {index: 0, rgb: [0, 109, 44]},
    {index: 0.1, rgb: [35, 139, 69]},
    {index: 0.2, rgb: [65, 171, 93]},
    {index: 0.3, rgb: [116, 196, 118]},
    {index: 0.4, rgb: [161, 217, 155]},
    {index: 0.5, rgb: [199, 233, 192]},
    {index: 0.6, rgb: [229, 245, 224]},
    {index: 0.7, rgb: [247, 252, 245]}
  ]

  const props = defineProps({
    layer: {
      type: Object,
      required: true
    },
    feature: {
      type: Object,
      required: true
    }
  })

  const geometry = props.feature
  const box = bbox(geometry)
  const topLeft = [box[0], box[3]]
  const topRight = [box[2], box[3]]
  const bottomRight = [box[2], box[1]]
  const bottomLeft = [box[0], box[1]]
  const corners = [
    topLeft,
    topRight,
    bottomRight,
    bottomLeft
  ]

  const chartData = ref(null)

  const idToLabel = {
    'ABOVEGROUND_CARBON_DENSITY_v1.0.0_30': 'ACD (Mg C⋅ha)',
    'CANOPY_HEIGHT_v1.0.0_30': 'm',
    'CANOPY_COVER_v1.0.0_30': '%'
  }

  const idToColormap = {
    'ABOVEGROUND_CARBON_DENSITY_v1.0.0_30': viridis,
    'CANOPY_HEIGHT_v1.0.0_30': greens,
    'CANOPY_COVER_v1.0.0_30': ylgn
  }

  const fetchChartData = async () => {
    try {
      const response = await API({
        method: 'GET',
        url: `${HOST_TULUM}/tasks/layers/${props.layer.uuid}/subscription/result`
      })
      return response.data.reverse()
    } catch (e) {
      console.error(e)
    }
  }

  const generateGradient = (ctx, chartArea, colormap, opacity=1) => {
    const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom)
    for (let color of colormap) {
      gradient.addColorStop(color.index, `rgb(${color.rgb.join(',')}, ${opacity})`)
    }
    return gradient
  }

  onMounted(async () => {
    const ctx = document.getElementById(`layer-chart-${props.layer.uuid}`).getContext('2d')
    const data = await fetchChartData()
    if (!data) return
    const labels = data.map((i) => {
      return new Date(i.properties.local_solar_time).getFullYear().toString()
    })
    const acd = data.map((i) => i.properties.statistics[0].value)

    chartData.value = {
      labels: labels,
      datasets: [
        {
          label: idToLabel[props.layer.source_id],
          data: acd,
          borderWidth: 2,
          borderColor: (context) => {
            const chartArea = context.chart.chartArea
            if (!chartArea) return null
            return generateGradient(ctx, chartArea, idToColormap[props.layer.source_id])
          },
          backgroundColor: (context) => {
            const chartArea = context.chart.chartArea
            if (!chartArea) return null
            return generateGradient(ctx, chartArea, idToColormap[props.layer.source_id], 0.5)
          },
          fill: true
        }
      ]
    }

    const chart = new Chart(ctx, {
      type: 'line',
      data: chartData.value,
      options: {
        animation: {
          onComplete: () => {
            isChartRendered.value = true
          }
        },
        onHover: function(event, chartElement) {
          if (!showImages.value) return

          if (chartElement.length > 0) {
            const index = chartElement[0].index
            const year = chartData.value.labels[index] 

            const existingLayer = map.value.getLayer(`${props.layer.action_id}-img-${year}-layer`);
            if (!existingLayer) {
              chartData.value.labels.forEach(label => {
                if (label !== year) {
                  if (map.value.getLayer(`${props.layer.action_id}-img-${label}-layer`)) {
                    map.value.removeLayer(`${props.layer.action_id}-img-${label}-layer`)
                  }
                }
              })

              map.value.addLayer({
                id: `${props.layer.action_id}-img-${year}-layer`,
                'type': 'raster',
                'source': `${props.layer.action_id}-img-${year}-source`,
                'paint': {
                  'raster-fade-duration': 0,
                  'raster-resampling': 'nearest'
                }
              })
            }
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            // min: 20,
            // max: 100,
            title: {
              display: true,
              text: idToLabel[props.layer.source_id],
              position: 'top'
            }
          }
        },
        responsive: true,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    })    
  })

  const handleShowImages = (value) => {
    showImages.value = value

    if (value) {
      if (!imagesLoaded.value) {
        if (!chartData.value) return

        for (let year of chartData.value.labels) {
          const url = `https://planet-basemaps.s3.us-west-2.amazonaws.com/layer-${props.layer.action_id}-${props.layer.source_id}-${year}.png`
          const sourceName = `${props.layer.action_id}-img-${year}-source`

          if (map.value.getSource(sourceName)) return

          map.value.addSource(sourceName, {
            'type': 'image',
            'url': url,
            'coordinates': corners
          })
        }
        imagesLoaded.value = true
      }

      const firstLabel = chartData.value.labels[0]
      map.value.addLayer({
        id: `${props.layer.action_id}-img-${firstLabel}-layer`,
        'type': 'raster',
        'source': `${props.layer.action_id}-img-${firstLabel}-source`,
        'paint': {
          'raster-fade-duration': 0,
          'raster-resampling': 'nearest'
        }
      })
    } else {
      chartData.value.labels.forEach(label => {
        if (map.value.getLayer(`${props.layer.action_id}-img-${label}-layer`)) {
          map.value.removeLayer(`${props.layer.action_id}-img-${label}-layer`)
        }
      })
    }
  }

  onUnmounted(() => {
    if (chartData.value) {
      chartData.value.labels.forEach(label => {
        if (map.value.getLayer(`${props.layer.action_id}-img-${label}-layer`)) {
          map.value.removeLayer(`${props.layer.action_id}-img-${label}-layer`)
        }
      })
    }
  })
</script>

<template>
  <div>
    <div class="flex items-center my-4" v-if="map && isChartRendered">
      <Switch
        :checked="showImages"
        @update:checked="handleShowImages"
      />
      <span class="pl-2">
        Show on map
      </span>
    </div>
    <Spinner v-else class="w-6 h-6" />
    <canvas v-show="chartData" :id="`layer-chart-${layer.uuid}`" width="100%" height="60px"></canvas>
  </div>
</template>