<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'

import { Button } from '@/components/ui/button'
import { ChevronLeft, ChevronRight } from 'lucide-vue-next'

const store = useStore()
const map = computed(() => store.getters['geo/getMap'])
const isLoggedIn = computed(() => store.getters.getIsLoggedIn)

const width = ref(330)
const isDragging = ref(false)
const initialWidth = ref(330)
const startX = ref(0)

const isLeftCollapsed = ref(false)
const lastLeftWidth = ref(0)

const onMouseMove = (event) => {
  if (isDragging.value) {
    const newWidth = initialWidth.value + (event.clientX - startX.value)
    width.value = Math.max(10, newWidth)
  }
}

const onMouseUp = () => {
  isDragging.value = false
  document.removeEventListener('mousemove', onMouseMove)
  document.removeEventListener('mouseup', onMouseUp)
}

const onMouseDown = (event) => {
  isDragging.value = true
  initialWidth.value = width.value
  startX.value = event.clientX

  document.addEventListener('mousemove', onMouseMove)
  document.addEventListener('mouseup', onMouseUp)
}

const toggleLeftCollapse = () => {
  isLeftCollapsed.value = !isLeftCollapsed.value
  if (isLeftCollapsed.value) {
    lastLeftWidth.value = width.value
    width.value = 0
  } else {
    width.value = lastLeftWidth.value
  }

  if (map.value) {
    setTimeout(() => {
      map.value.resize()
    }, 300)
  }
}

onMounted(() => {
  document.addEventListener('mouseup', onMouseUp)
})
</script>

<template>
  <div class="flex w-full" style="max-height: calc(100vh - 100px); min-height: calc(100vh - 100px);">
    <div v-if="isLoggedIn" :style="{ width: `${width}px` }" class="relative z-20" :class="{'transition-width duration-300 ease-in-out': !isDragging}">
      <div class="overflow-hidden w-full h-full">
        <div class="overflow-x-auto w-full h-full">
          <slot name="left"></slot>
        </div>
      </div>
      <div
        class="w-1 h-full cursor-col-resize absolute right-0 top-0"
        :class="{'bg-gray-200': isDragging}"
        @mousedown="onMouseDown"
      >
      </div>
      <Button class="shadow-lg absolute top-1/2 -right-5 transform -translate-y-1/2 z-30 w-5 h-10 p-0 rounded-none rounded-r-md" size="sm" @click="toggleLeftCollapse">
        <ChevronRight class="w-5 h-5" v-if="isLeftCollapsed" />
        <ChevronLeft class="w-5 h-5" v-else />
      </Button>
    </div>
    <div class="flex-1 z-10 shadow-lg">
      <div class="overflow-hidden w-full h-full">
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>
