<script setup>
import {computed, ref, onMounted} from 'vue'
import {useStore} from 'vuex'
import {formatTime} from "@/utils/datetime"
import {API, HOST_TULUM} from '@/utils/http'

import {CheckCircle2, XCircle} from 'lucide-vue-next'
import {Spinner} from '@/components/ui/spinner'
import {Switch} from '@/components/ui/switch'
import {Trash2} from 'lucide-vue-next'
import {Button} from '@/components/ui/button'
import LayerChart from './LayerChart.vue'
import {DialogConfirm} from '@/components/dialogs'


const store = useStore()
const settings = computed(() => store.getters['user/getUserSettings'])
const map = computed(() => store.getters['geo/getMap'])


const props = defineProps({
  layer: {
    type: Object,
    required: true
  },
  feature: {
    type: Object,
    required: true
  }
})

const protocol = location.protocol === 'https:' ? 'https' : 'http'
const host = `${protocol}://${window.location.host}`

const statusToLabel = {
  '-9': 'Preparing Data (Failed)',
  '-8': 'Cleaning Up (Failed)',
  '-7': 'Uploading Tiles (Failed)',
  '-6': 'Generating Tiles (Failed)',
  '-5': 'Merging Quads (Failed)',
  '-4': 'Uploading Data (Failed)',
  '-3': 'Delivering Data (Failed)',
  '-2': 'Ordering Data (Failed)',
  '-1': 'Starting (Failed)',
  '0': 'Done',
  '1': 'Starting',
  '2': 'Ordering Data',
  '3': 'Delivering Data',
  '4': 'Uploading Data',
  '5': 'Merging Quads',
  '6': 'Generating Tiles',
  '7': 'Uploading Tiles',
  '8': 'Cleaning Up',
  '9': 'Preparing Data'
}


const typeToLabel = {
  'forest_carbon_diligence_30m': 'Forest Carbon Diligence 30m'
}

const idToSource = {
  'ABOVEGROUND_CARBON_DENSITY_v1.0.0_30': 'Aboveground Carbon Density 30m (v1.0.0)',
  'CANOPY_HEIGHT_v1.0.0_30': 'Canopy Height 30m (v1.0.0)',
  'CANOPY_COVER_v1.0.0_30': 'Canopy Cover  30m (v1.0.0)'
}

const isTileActive = ref(false)
const tileLayer = {
  status: false,
  name: 'Skysat Demo',
  map: null // add mapbox tile layer
}

const fetchLayerImages = async () => {
  try {
    const response = await API({
      method: 'GET',
      url: `${HOST_TULUM}/tasks/layers/${props.layer.uuid}/subscription/images`
    })
    return response.data
  } catch (e) {
    console.error(e)
  }
}

const toggleLayer = async () => {
  const images = await fetchLayerImages()
}

const deleteLayer = async () => {
  try {
    const response = await API({
      method: 'DELETE',
      url: `${HOST_TULUM}/tasks/layers/${props.layer.uuid}`
    })

    store.commit('planet/DELETE_LAYER', props.layer.id)
  } catch (e) {
    console.error(e)
  }
}
</script>

<template>
  <div class="bg-white rounded-lg shadow mb-3 px-3 py-3">
    <div class="flex">
      <div>
        <h3 class="font-bold mb-1">{{layer.name}}</h3>
        <!-- <p class="text-gray-500 mb-2">{{typeToLabel[layer.source_type]}}</p> -->
        <p class="text-gray-500">{{idToSource[layer.source_id]}}</p>
      </div>
      <DialogConfirm
        ref="dialogDeleteConfirm"
        @confirm="deleteLayer"
        title="Delete layer"
        description="Are you sure you want to delete this layer?"
      >
        <Button size="sm" variant="ghost" class="w-6 h-6 p-1">
          <Trash2 class="text-red-400" />
        </Button>
      </DialogConfirm>
    </div>

    <div class="flex items-center my-3">
      <div class="mr-2">
        <CheckCircle2 v-if="layer.status === 0" class="text-green-600 w-5 h-5" />
        <XCircle v-else-if="parseInt(layer.status) < 0" class="text-red-400 w-5 h-5" />
        <Spinner v-else class="w-5 h-5" />
      </div>
      <h4>{{ statusToLabel[layer.status] }}</h4>
    </div>

    <p class="text-gray-500 mb-0">{{formatTime(layer.created_at, settings.tz, 'D MMMM YYYY HH:mm')}}</p>

    <LayerChart
      v-if="layer.status === 0"
      :layer="layer"
      class="mt-4"
      :feature="feature"
      :key="feature.properties.code"
    />
    <!-- <div class="text-gray-500">Layer ID:{{ layer.uuid }}</div>
    <div class="text-gray-500">Action ID:{{ layer.action_id }}</div> -->
  </div>
</template>
