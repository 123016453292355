<script setup>
// Notes:
// 1. onMounted initializing mapbox
// 2. on mapbox load creating sources and layers, adding listeners
// 3. on mapbox load initializing watchEffect for update map on filters data change
// 4. on mapbox dragend and zoomend updating map data

import { ref, watch, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import {useMouse} from '@vueuse/core'
import { debounce } from 'lodash'

import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import mapboxgl from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { bbox, area, centroid, length } from '@turf/turf'

import { API, HOST } from '@/utils/http'
import { DialogCreateFeature } from '@/components/dialogs'
import { Spinner } from '@/components/ui/spinner'
import { DialogCreate, SideBySide } from '@/components/map'
import { FormProject, FormFeature } from '@/components/forms'

import { toast } from '@/components/ui/toast'

mapboxgl.accessToken = 'pk.eyJ1IjoiZWFydGhwYmMiLCJhIjoiY2x1dGRiMGU4MDdrdjJsb2ducG5pbG41ayJ9.XiX9hVsMSiOXSPf78V3dag'

const { x, y } = useMouse()

const router = useRouter()
const route = useRoute()
const projectId = computed(() => parseInt(route.params.project_id))

const store = useStore()
const isLoggedIn = computed(() => store.getters.getIsLoggedIn)
const isFocusOnFilteredData = computed(() => store.getters['geo/getIsFocusOnFilteredData'])
const layersToken = computed(() => store.getters['geo/getTilesToken'])
const layers = computed(() => store.getters['geo/getLayers'])
const beacons = computed(() => store.getters['geo/getBeacons'])
const projects = computed(() => store.getters['project/getProjects'])
const flatedProjects = computed(() => store.getters['project/getFlatedProjects'])
const currentProject = computed(() => store.getters['project/current'])
const params = computed(() => store.getters['search/getParams'])
const mapParamsKeys = ['zoom', 'center_lat', 'center_lng', 'south', 'north', 'east', 'west']

const activeSources = ref([])
const activeLayers = ref([])

const isLoadingData = ref(false)
const filterParams = computed(() => {
  return params.value.filter((param) => !mapParamsKeys.includes(param.key))
})

const isShowDetails = ref(false)
const hoveredFeature = ref(null)

const createFeatureDialog = ref(null)
const mapParams = computed(() => {
  const zoom = params.value.find((param) => param.key === 'zoom')
  const centerLat = params.value.find((param) => param.key === 'center_lat')
  const centerLng = params.value.find((param) => param.key === 'center_lng')

  return zoom && centerLat && centerLng ? {
    center: [centerLng.value, centerLat.value],
    zoom: zoom.value
  } : null
})

const localMapOptions = JSON.parse(localStorage.getItem('mapOptions'))

const map = ref(null)
const draw = ref(null)
const currentDrawMode = ref('none')
const drawedData = ref(null)

const defaultOptions = {
  center: [-91.874, 42.76],
  zoom: 4
}

const protocol = location.protocol === 'https:' ? 'https' : 'http'
const host = `${protocol}://${window.location.host}`
const baseSources = {
  'osm-tiles-source': {
    'type': 'raster',
    'tiles': ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
    'tileSize': 256,
    'attribution':
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  },
  'osmtopo-tiles-source': {
    'type': 'raster',
    'tiles': ['https://tile.opentopomap.org/{z}/{x}/{y}.png'],
    'tileSize': 256,
    'attribution':
      'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, ' +
      '<a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">' +
      'OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
  }
}

const baseLayers = [
  {
    'id': 'osm-tiles-layer',
    'type': 'raster',
    'source': 'osm-tiles-source',
    'minzoom': 2,
    'maxzoom': 18
  },
  {
    'id': 'osmtopo-tiles-layer',
    'type': 'raster',
    'source': 'osmtopo-tiles-source',
    'minzoom': 2,
    'maxzoom': 18,
    'layout': {
      'visibility': 'none'
    }
  }
]


const createMap = async () => {
  if (isLoggedIn.value) {
    await store.dispatch('geo/fetchLayersToken')
    await store.dispatch('geo/fetchLayers')
  }

  const planetSources = {}
  const planetLayers = []

  // set basemap tiles
  layers.value?.globals.forEach(layer => {
    planetSources[`planet-${layer.name}-source`] = {
      'type': 'raster',
      'tiles': [`${host}/tulum/tiles/1/${layer.name}/{z}/{x}/{y}.png?token=${layersToken.value}`],
      'tileSize': 256,
      'attribution': '&copy; <a href="https://www.planet.com/">Planet</a>'
    }

    planetLayers.push({
      'id': `planet-${layer.name}-layer`,
      'type': 'raster',
      'source': `planet-${layer.name}-source`,
      'minzoom': 2,
      'maxzoom': 18,
      'layout': {
        'visibility': 'none'
      }
    })
  })

  const style = {
    'glyphs': 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
    'version': 8,
    'sources': {
      ...baseSources,
      ...planetSources
    },
    'layers': [
      ...baseLayers,
      ...planetLayers
    ]
  }

  map.value = new mapboxgl.Map({
    container: 'mapDiv',
    style: style,
    minZoom: 2,
    maxZoom: 17,
    // merge options from local storage with default
    ...mapParams.value ? mapParams.value : localMapOptions ? localMapOptions : defaultOptions
  })

  // map.value._controls.forEach(control => {
  //   if (control instanceof mapboxgl.AttributionControl) {
  //     map.value.removeControl(control)
  //   }
  // })
  // map.value.addControl(new mapboxgl.AttributionControl({
  //   customAttribution: `© ${new Date().getFullYear()} Earth PBC. All Rights Reserved.`
  // }), 'bottom-right')

  draw.value = new MapboxDraw({
    displayControlsDefault: false,
    styles: [
      {
        'id': 'highlight-active-points',
        'type': 'circle',
        'filter': ['all',
          ['==', '$type', 'Point'],
          ['==', 'meta', 'feature'],
          ['==', 'active', 'true']
        ],
        'paint': {
          'circle-radius': 12,
          'circle-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'circle-stroke-width': 3,
          'circle-stroke-color': '#fff',
        }
      },
      {
        'id': 'highlight-active-points-outlinebox',
        'type': 'circle',
        'filter': ['all',
          ['==', '$type', 'Point'],
          ['==', 'meta', 'feature'],
          ['==', 'active', 'true']
        ],
        'paint': {
          'circle-radius': 18,
          'circle-color': ['case', ['has', 'color'], ['get', 'color'], 'rgba(0, 0, 0, 0)'],
          'circle-stroke-width': 2,
          'circle-stroke-color': '#000000',
        }
      },
      {
        'id': 'points-are-blue',
        'type': 'circle',
        'filter': ['all',
          ['==', '$type', 'Point'],
          ['==', 'meta', 'feature'],
          ['==', 'active', 'false']
        ],
        'paint': {
          'circle-radius': 10,
          'circle-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'circle-stroke-width': 3,
          'circle-stroke-color': '#fff'
        }
      },
      // ACTIVE (being drawn)
      // line stroke
      {
        'id': 'gl-draw-line',
        'type': 'line',
        'filter': ['all',
          ['==', '$type', 'LineString'],
          ['==', 'active', 'true']
        ],
        'layout': {
          'line-cap': 'round',
          'line-join': 'round'
        },
        'paint': {
          'line-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'line-dasharray': [0.2, 2],
          'line-width': 4
        }
      },
      // polygon fill
      {
        'id': 'gl-draw-polygon-fill',
        'type': 'fill',
        'filter': ['all',
          ['==', '$type', 'Polygon'],
          ['==', 'active', 'true']
        ],
        'paint': {
          'fill-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'fill-outline-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'fill-opacity': 0.1
        }
      },
      // polygon outline stroke
      {
        'id': 'gl-draw-polygon-stroke-active',
        'type': 'line',
        'filter': ['all',
          ['==', '$type', 'Polygon'],
          ['==', 'active', 'true']
        ],
        'layout': {
          'line-cap': 'round',
          'line-join': 'round'
        },
        'paint': {
          'line-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'line-dasharray': [0.2, 2],
          'line-width': 4
        }
      },
      // vertex points
      {
        'id': 'gl-draw-points-active',
        'type': 'circle',
        'filter': ['all',
          ['==', 'meta', 'vertex'],
          ['==', '$type', 'Point'],
          ['==', 'active', 'true']
        ],
        'paint': {
          'circle-radius': 8,
          'circle-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'circle-stroke-width': 3,
          'circle-stroke-color': '#fff'
        }
      },
      // INACTIVE (static, already drawn)
      // line stroke
      {
        'id': 'gl-draw-line-static',
        'type': 'line',
        'filter': ['all',
          ['==', '$type', 'LineString'],
          ['==', 'active', 'false']
        ],
        'layout': {
          'line-cap': 'round',
          'line-join': 'round'
        },
        'paint': {
          'line-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'line-width': 2
        }
      },
      // polygon fill
      {
        'id': 'gl-draw-polygon-fill-static',
        'type': 'fill',
        'filter': ['all',
          ['==', '$type', 'Polygon'],
          ['==', 'active', 'false']
        ],
        'paint': {
          'fill-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'fill-outline-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'fill-opacity': 0.1
        }
      },
      // polygon outline
      {
        'id': 'gl-draw-polygon-stroke-static',
        'type': 'line',
        'filter': ['all',
          ['==', '$type', 'Polygon'],
          ['==', 'active', 'false']
        ],
        'layout': {
          'line-cap': 'round',
          'line-join': 'round'
        },
        'paint': {
          'line-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'line-width': 2
        }
      },
      // vertex points
      {
        'id': 'gl-draw-points-static',
        'type': 'circle',
        'filter': ['all',
          ['==', 'meta', 'vertex'],
          ['==', '$type', 'Point'],
          ['==', 'active', 'false']
        ],
        'paint': {
          'circle-radius': 7,
          'circle-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'circle-stroke-width': 2,
          'circle-stroke-color': '#fff'
        }
      }
    ]
  });

  map.value.addControl(new mapboxgl.NavigationControl(), 'bottom-right')
  map.value.addControl(
    new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true,
      showUserHeading: true
    }), 'bottom-right')
    map.value.addControl(draw.value, 'bottom-right')

  store.commit('geo/SET_MAP', map.value)
  store.commit('geo/SET_DRAW', draw.value)
}

const createSources = async (projectId) => {
  const projectStr = `project-${projectId}-`

  if (!map.value.getSource(`${projectStr}reports_points`)) {
    map.value.addSource(`${projectStr}reports_points`, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      },
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 50
    })
    activeSources.value.push(`${projectStr}reports_points`)
  }

  if (!map.value.getSource(`${projectStr}reports_mixed`)) {
    map.value.addSource(`${projectStr}reports_mixed`, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      },
      'generateId': true
    })
    activeSources.value.push(`${projectStr}reports_mixed`)
  }

  if (!map.value.getSource(`${projectStr}beacons`)) {
    map.value.addSource(`${projectStr}beacons`, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      },
      'generateId': true
    })
    activeSources.value.push(`${projectStr}beacons`)
  }
}

const createLayers = async (projectId) => {
  const projectStr = `project-${projectId}-`
  // check if layer exists
  if (!map.value.getLayer(`${projectStr}report-fill`)) {
    map.value.addLayer({
      'id': `${projectStr}report-fill`,
      'type': 'fill',
      'source': `${projectStr}reports_mixed`,
      'paint': {
        'fill-color': ['get', 'color'],
        'fill-opacity': 0.02
      },
      'filter': ['==', '$type', 'Polygon']
    })
    activeLayers.value.push(`${projectStr}report-fill`)
    setLayerListeners(`${projectStr}report-fill`)
  }

  if (!map.value.getLayer(`${projectStr}report-lines`)) {
    map.value.addLayer({
      'id': `${projectStr}report-lines`,
      'type': 'line',
      'source': `${projectStr}reports_mixed`,
      'paint': {
        'line-color': ['get', 'color'],
        'line-width': 3
      },
      'filter': ['any', ['==', '$type', 'LineString'], ['==', '$type', 'Polygon']]
    })
    activeLayers.value.push(`${projectStr}report-lines`)
    setLayerListeners(`${projectStr}report-lines`)
  }

  if (!map.value.getLayer(`${projectStr}clusters`)) {
    map.value.addLayer({
      id: `${projectStr}clusters`,
      type: 'circle',
      source: `${projectStr}reports_points`,
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': ['step', ['get', 'point_count'], '#2259ff', 100, '#2259ff', 750, '#2259ff'],
        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
        'circle-stroke-width': 2,
        'circle-stroke-color': '#fff'
      }
    })
    activeLayers.value.push(`${projectStr}clusters`)
    setLayerListeners(`${projectStr}clusters`)
  }

  if (!map.value.getLayer(`${projectStr}cluster-count`)) {
    map.value.addLayer({
      id: `${projectStr}cluster-count`,
      type: 'symbol',
      source: `${projectStr}reports_points`,
      filter: ['has', 'point_count'],
      layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-size': 18
      },
      paint: {
        'text-color': '#fff'
      }
    })
    activeLayers.value.push(`${projectStr}cluster-count`)
    setLayerListeners(`${projectStr}cluster-count`)
  }

  if (!map.value.getLayer(`${projectStr}unclustered-point`)) {
    map.value.addLayer({
      id: `${projectStr}unclustered-point`,
      type: 'circle',
      source: `${projectStr}reports_points`,
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': ['get', 'color'], // Use color from feature properties
        'circle-radius': 8,
        'circle-stroke-width': 2,
        'circle-stroke-color': '#fff'
      }
    })
    activeLayers.value.push(`${projectStr}unclustered-point`)
    setLayerListeners(`${projectStr}unclustered-point`)
  }

  if (!map.value.getLayer(`${projectStr}beacons`)) {
    map.value.addLayer({
      id: `${projectStr}beacons`,
      type: 'circle',
      source: `${projectStr}beacons`,
      paint: {
        'circle-color': '#000000',
        'circle-radius': 20,
        'circle-stroke-width': 4,
        'circle-stroke-color': '#ffffff'
      }
    })

    activeLayers.value.push(`${projectStr}beacons`)
    setLayerListeners(`${projectStr}beacons`)
  }
}

const updateBounds = () => {
  const bounds = map.value.getBounds()
  const center = map.value.getCenter()

  const options = {
    center_lat: center.lat,
    center_lng: center.lng,
    zoom: map.value.getZoom(),
    south: bounds.getSouth(),
    north: bounds.getNorth(),
    east: bounds.getEast(),
    west: bounds.getWest(),
  }

  for (const [key, value] of Object.entries(options)) {
    store.commit('search/ADD_PARAM', {
      key: key,
      value: value
    })
  }

  localStorage.setItem('mapOptions', JSON.stringify({
    center: map.value.getCenter(),
    zoom: map.value.getZoom()
  }))
}

const saveFeature = async (feature) => {
  await createFeatureDialog.value.open(feature)
}

const startDraw = (type) => {
  draw.value.changeMode(type)
}

const resetDrawing = () => {
  draw.value.deleteAll()
  drawedData.value = []
}

const onDrawUpdate = async (e) => {
  const data = draw.value.getAll()
  drawedData.value = []

  for (const [index, feature] of data.features.entries()) {
    let areaText = ''

    if (feature.geometry.type === 'Polygon') {
      const areaInSm = area(feature)
      const areaInHa = (areaInSm / 10000).toFixed(3) // 1 ha = 10000 sm
      const areaInSkm = (areaInSm / 1000000).toFixed(4) // 1 skm = 1000000 sm
      areaText = `${areaInHa} ha (${areaInSkm} km²)`

      feature.properties = {
        ...feature.properties,
        areaInSm: areaInSm,
        areaInHa: areaInHa,
        areaInSkm: areaInSkm,
        areaText: areaText
      }
    }

    if (feature.geometry.type === 'LineString') {
      const lengthInM = length(feature, { units: 'meters' })
      const lengthInKm = (lengthInM / 1000).toFixed(2)
      areaText = `${lengthInKm} km`

      feature.properties = {
        ...feature.properties,
        lengthInM: lengthInM,
        lengthInKm: lengthInKm,
        areaText: areaText
      }
    }

    const areaCenter = centroid(feature)
    const sourceId = 'areaLabelSource-' + feature.id
    const layerId = 'areaLabel-' + feature.id

    feature.properties = {
      ...feature.properties,
      areaCenter: areaCenter
    }

    drawedData.value.push(feature)

    if (map.value.getSource(sourceId)) {
      map.value.getSource(sourceId).setData(areaCenter)
      map.value.setLayoutProperty(layerId, 'text-field', areaText)
    } else {
      map.value.addSource(sourceId, {
        type: 'geojson',
        data: areaCenter
      })

      map.value.addLayer({
        id: layerId,
        type: 'symbol',
        source: sourceId,
        layout: {
          'text-field': areaText,
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-radial-offset': 0.5,
          'text-justify': 'auto',
          'text-size': 13
        }
      })
    }
  }
}


const deleteFeature = async (feature) => {
  if (!feature.id) return

  draw.value.delete(feature.id)
  cleanUpFeature(feature)
  drawedData.value = drawedData.value.filter((item) => item.id !== feature.id)
}

const cleanUpFeature = async (feature) => {
  const sourceId = 'areaLabelSource-' + feature.id
  const layerId = 'areaLabel-' + feature.id
  if (map.value.getSource(sourceId)) {
    map.value.removeLayer(layerId)
    map.value.removeSource(sourceId)
  }
}

const onDrawDelete = async (e) => {
  for (const feature of e.features) {
    cleanUpFeature(feature)
  }
}

const focusOnFeature = (feature) => {
  const bounds = bbox(feature)
  map.value.fitBounds(bounds, { padding: 50, duration: 600 })
}

const previewFeature = (feature) => {
  if (map.value.getLayer('preview-polygon-fill')) {
    map.value.removeLayer('preview-polygon-fill')
  }

  if (map.value.getLayer('preview-polygon-lines')) {
    map.value.removeLayer('preview-polygon-lines')
  }

  if (map.value.getSource('preview-polygon')) {
    map.value.removeSource('preview-polygon')
  }

  if (!feature) return

  if (!map.value.getSource('preview-polygon')) {
    map.value.addSource('preview-polygon', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [feature]
      },
      'generateId': true
    })
  }

  if (!map.value.getLayer('preview-polygon-fill')) {
    map.value.addLayer({
      'id': 'preview-polygon-fill',
      'type': 'fill',
      'source': 'preview-polygon',
      'paint': {
        'fill-color': '#059669',
        'fill-opacity': 0.1
      },
      'filter': ['==', '$type', 'Polygon']
    })
  }

  if (!map.value.getLayer('preview-polygon-lines')) {
    map.value.addLayer({
      'id': 'preview-polygon-lines',
      'type': 'line',
      'source': 'preview-polygon',
      'paint': {
        'line-color': '#059669',
        'line-width': 3
      },
      'filter': ['any', ['==', '$type', 'Polygon']]
    })
  }

  const bounds = bbox(feature)
  map.value.fitBounds(bounds, { padding: 50, duration: 600 })
}

const onCreated = async (feature) => {
  deleteFeature(feature)

  await loadData()
  await store.commit('geo/SET_FEATURE_CREATED', true)
}

const setListeners = () => {
  map.value.on('load', async () => {
    await store.dispatch('geo/mapReady', true)
    await loadData()
  })

  map.value.on('dragend', async () => {
    updateBounds()
    await loadData()
  })

  map.value.on('zoomend', async () => {
    updateBounds()
    await loadData()
  })

  // DRAWING
  map.value.on('draw.create', onDrawUpdate)
  map.value.on('draw.update', onDrawUpdate)
  map.value.on('draw.delete', onDrawDelete)

  map.value.on('mousemove', (e) => () => {
    if (!draw.value.getMode()) return
    if (currentDrawMode.value === draw.value.getMode()) return
    currentDrawMode.value = draw.value.getMode()
  })

  // zoom on clicked cluster
  // map.value.on('click', 'clusters', (e) => {
  //   const features = map.value.queryRenderedFeatures(e.point, {
  //     layers: ['clusters']
  //   })
  //   const clusterId = features[0].properties.cluster_id
  //   map.value.getSource('reports_points').getClusterExpansionZoom(
  //     clusterId,
  //     (err, zoom) => {
  //       if (err) return;

  //       map.value.easeTo({
  //         center: features[0].geometry.coordinates,
  //         zoom: zoom
  //       })
  //     }
  //   )
  // })
}

onMounted(async () => {
  await createMap()
  await setListeners()
})

// const findProjectById = (projectId) => {
//   for (let project of flatedProjects.value) {
//     if (project.project_id === projectId) {
//       return project
//     }
//     if (project.children && project.children.length > 0) {
//       const result = findProjectById(project.children, projectId)
//       if (result) {
//         return result
//       }
//     }
//   }
//   return null
// }

const loadData = async () => {
  if (!map.value) return

  if (!map.value.getLayer('highlight-active-points-outlinebox')) {
    if (map.value.getLayer('highlight-active-points')) {
      map.value.addLayer('highlight-active-points-outlinebox', 'highlight-active-points')
    }
  }

  if (!isLoggedIn.value) return

  if (!projectId.value) {
    console.log('loadData: no projectId')
    for (const project of projects.value) {
      setProjectFeature(project)
    }
  } else {
    const currentProject = flatedProjects.value.find((project) => project.project_id === projectId.value)
    if (currentProject?.children?.length > 0) {
      for (const project of currentProject.children) {
        setProjectFeature(project)
      }
    }

    await createSources(projectId.value)
    await createLayers(projectId.value)
    console.log('loadData: projectId', projectId.value)
  }

  if (!projectId.value) return

  isLoadingData.value = true
  const features = await fetchData()
  if (!features) return
  setFeatures(features)

  if (isFocusOnFilteredData.value && features.features.length !== 0) {
    const bounds = bbox(features)
    map.value.fitBounds(bounds, {
      padding: 50,
      duration: 600
    })
    store.commit('geo/SET_FOCUS_ON_FILTERED_DATA', false)
  }

  isLoadingData.value = false
}

const fetchData = async () => {
  try {
    const paramsMap = params.value.reduce((acc, param) => {
      acc[param.key] = param.value
      return acc
    }, {})

    const filterParamsMap = filterParams.value.reduce((acc, param) => {
      acc[param.key] = param.value
      return acc
    }, {})

    const payload = {
      shared: filterParamsMap['shared'],
      has_resources: filterParamsMap['has_resources'],
      start_date: filterParamsMap['start_date'],
      end_date: filterParamsMap['end_date'],
      is_meta: false,

      // map params
      south: isFocusOnFilteredData.value ? null : paramsMap['south'],
      north: isFocusOnFilteredData.value ? null : paramsMap['north'],
      east: isFocusOnFilteredData.value ? null : paramsMap['east'],
      west: isFocusOnFilteredData.value ? null : paramsMap['west'],
    }

    const response = await API({
      method: 'GET',
      url: `${HOST}/geo/features/${projectId.value}`,
      params: payload
    })

    store.commit('geo/SET_TOTAL_ONMAP', response.data?.total)

    return response ? response.data.features : null
  } catch (e) {
    if (e.response?.status === 404) {
      toast({title: `Project data not found`})
    } else {
      throw new Error(e)
    }
  }
}

const setFeatures = (features) => {
  if (!map.value) return

  const featurePages = ['Feature', 'FeatureIndex', 'FeatureEdit']
  const isFeaturePage = featurePages.includes(route.name)
  const featureCode = isFeaturePage ? route.params.featureCode : null
  const points = features.features.filter((feature) => feature.geometry.type === 'Point' && feature.properties.code !== featureCode)
  const mixedGeometry = features.features.filter((feature) => feature.geometry.type !== 'Point' && feature.properties.code !== featureCode)
  const projectStr = `project-${projectId.value}-`

  map.value.getSource(`${projectStr}reports_points`).setData({
    type: 'FeatureCollection',
    features: points
  })

  map.value.getSource(`${projectStr}reports_mixed`).setData({
    type: 'FeatureCollection',
    features: mixedGeometry
  })
}

const setLayerListeners = (layer) => {
  map.value.on('mousemove', layer, (e) => {
    map.value.getCanvas().style.cursor = 'pointer'
    isShowDetails.value = true
    hoveredFeature.value = e.features[0]
  })

  map.value.on('mouseleave', layer, () => {
    map.value.getCanvas().style.cursor = 'default'
    isShowDetails.value = false
    hoveredFeature.value = null
  })

  // CLICK
  map.value.on('click', layer, (e) => {
    const feature = e.features[0]
    const bounds = bbox(feature)
    map.value.fitBounds(bounds, { padding: 50 })

    if (feature.properties.paired_project_id) {
      router.push({ name: 'Project', params: { project_id: feature.properties.paired_project_id } })
    } else {
      router.push({ name: 'Feature', params: { featureCode: feature.properties.code } })
    }
  })
}

const removeLayerListeners = (layer) => {
  map.value.off('mousemove', layer)
  map.value.off('mouseleave', layer)
  map.value.off('click', layer)
}

const getProjectBoundary = async (featureCode) => {
  try {
    const response = await API({
      method: 'GET',
      url: `${HOST}/geo/feature/${featureCode}`
    })

    return response.data
  } catch (e) {
    if (e.response?.status === 404) {
      toast({title: `Project boundary not found`})
    } else {
      throw new Error(e)
    }
  }
}


const setProjectFeature = async (project) => {
  if (!project.paired_feature_code) return

  const projectStr = `project-${project.project_id}-`
  const feature = await getProjectBoundary(project.paired_feature_code)

  if (!feature) return

  // create polygon source and layer with label layer
  if (!map.value.getSource(`${projectStr}polygon`)) {
    map.value.addSource(`${projectStr}polygon`, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [feature]
      },
      'generateId': true
    })
    activeSources.value.push(`${projectStr}polygon`)
  }

  if (!map.value.getLayer(`${projectStr}polygon-fill`)) {
    map.value.addLayer({
      'id': `${projectStr}polygon-fill`,
      'type': 'fill',
      'source': `${projectStr}polygon`,
      'paint': {
        'fill-color': 'black',
        'fill-opacity': 0.1
      },
      'filter': ['==', '$type', 'Polygon']
    })
    activeLayers.value.push(`${projectStr}polygon-fill`)
    setLayerListeners(`${projectStr}polygon-fill`)
  }

  if (!map.value.getLayer(`${projectStr}polygon-lines`)) {
    map.value.addLayer({
      'id': `${projectStr}polygon-lines`,
      'type': 'line',
      'source': `${projectStr}polygon`,
      'paint': {
        'line-color': 'black',
        'line-width': 1
      },
      'filter': ['any', ['==', '$type', 'Polygon']]
    })
    activeLayers.value.push(`${projectStr}polygon-lines`)
    setLayerListeners(`${projectStr}polygon-lines`)
  }

  const areaCenter = centroid(feature)
  areaCenter.properties = feature.properties

  if (!map.value.getSource(`${projectStr}areaLabelSource`)) {
    map.value.addSource(`${projectStr}areaLabelSource`, {
      type: 'geojson',
      data: areaCenter
    })
    activeSources.value.push(`${projectStr}areaLabelSource`)
  }

  if (!map.value.getLayer(`${projectStr}areaLabel`)) {
    map.value.addLayer({
      id: `${projectStr}areaLabel`,
      type: 'symbol',
      source: `${projectStr}areaLabelSource`,
      layout: {
        'text-field': project.project_name,
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-radial-offset': 0.5,
        'text-justify': 'auto',
        'text-size': 16,
        'text-font': ['Open Sans Bold']
      },
      'paint': {
        'text-color': 'white',
        'text-halo-color': 'black',
        'text-halo-width': 1
      },
    })
    activeLayers.value.push(`${projectStr}areaLabel`)
    setLayerListeners(`${projectStr}areaLabel`)
  }
}

const resetMapData = () => {
  for (const layer of activeLayers.value) {
    if (map.value.getLayer(layer)) {
      map.value.removeLayer(layer)
      activeLayers.value = activeLayers.value.filter((item) => item !== layer)
      removeLayerListeners(layer)
    }
  }

  for (const source of activeSources.value) {
    if (map.value.getSource(source)) {
      map.value.removeSource(source)
      activeSources.value = activeSources.value.filter((item) => item !== source)
    }
  }
}

const closeFeatureForm = () => {
  store.commit('project/SET_SHOW_PROJECT_FORM', false)
  store.commit('geo/SET_CONTEXT_FORM_FEATURE', null)
  resetDrawing()
}

watch(() => drawedData.value, async (value) => {
  store.commit('geo/SET_CONTEXT_FORM_FEATURE', value[0])
})

watch(
  () => route.name,
  async (value) => {
    await loadData()
  }
)

watch(
  () => projectId.value,
  async (value) => {
    resetMapData()
    await loadData()
  }
)

watch(
  () => filterParams.value,
  async (newValue, oldValue) => {
    if (!oldValue) return

    await loadData()
  },
  { deep: true, immediate: true }
)

watch(
  () => isFocusOnFilteredData.value,
  async (value) => {
    if (value) {
      await loadData()
      store.commit('geo/SET_FOCUS_ON_FILTERED_DATA', false)
    }
  }
)

let markers = []
watch(
  () => beacons.value,
  async (value) => {
    // Remove existing markers
    markers.forEach(marker => marker.remove())
    markers = []

    for (const beacon of value) {
      const { member_avatar_url, type, member_display_name } = beacon.properties

      // marker layout
      const markerWrap = document.createElement('div')
      const markerEl = document.createElement('div')
      const image = document.createElement('div')
      const ping = document.createElement('div')
      const name = document.createElement('div')

      markerEl.className = 'flex flex-col items-center justify-center'

      image.style.backgroundImage = `url(${member_avatar_url})`
      image.className = 'relative user-beacon w-10 h-10 z-10 rounded-full bg-white border-2 border-white shadow-lg'

      ping.className = `animate-ping w-10 h-10 absolute inline-flex h-full w-full rounded-full opacity-75 ${type === 4 ? 'bg-red-500' : 'bg-primary'}`

      name.className = 'text-xs whitespace-nowrap backdrop-blur-sm bg-white/40 text-center mt-1 rounded px-1 py-0.5 shadow-lg'
      name.innerText = member_display_name || 'Unknown'


      markerEl.appendChild(image)
      image.appendChild(ping)
      markerWrap.appendChild(markerEl)
      markerWrap.appendChild(name)

      const marker = new mapboxgl.Marker({
        anchor: 'bottom',
        element: markerWrap,
      })
      .setLngLat(beacon.geometry.coordinates)
      .addTo(map.value)

      markers.push(marker)
    }
  },
  { deep: true }
)
</script>

<template>
  <div v-if="currentDrawMode.includes('draw')" :style="{ top: `${y+10}px`, left: `${x+10}px` }" class="fixed bg-white rounded shadow-lg px-1 py-0.5 z-50">
    <span>Click to create or update a geometry</span>
  </div>

  <div v-if="isShowDetails" :style="{ top: `${y+10}px`, left: `${x+10}px` }" class="fixed bg-white rounded shadow-lg px-1 py-0.5 z-50">
    {{ hoveredFeature?.properties?.name }}
  </div>

  <div class="relative min-h-full w-full h-full bg-slate-100 overflow-hidden">
    <div id="mapDiv" class="map w-full h-full"></div>
    <DialogCreateFeature v-if="projects.length" ref="createFeatureDialog" @update:created="(e) => onCreated(e)" />

    <!-- <div class="bg-white absolute z-50 top-0 left-0 w-[300px] py-1 px-2 m-1 rounded shadow-lg max-h-[600px] overflow-y-auto">
      <h4>Debug</h4>
      <div v-if="beacons?.length">({{beacons?.length}}) {{ beacons }}</div>
      <div>{{ params }}</div>

      <br>

      <h4>Sources:</h4>
      <div v-for="(source, index) of activeSources">
        {{index}}: {{ source }} <br>
      </div>

      <br>

      <h4>Layers:</h4>
      <div v-for="(layer, index) of activeLayers">
        {{index}}: {{ layer }} <br>
      </div>
    </div> -->

    <div v-if="isLoadingData" class="absolute z-50 top-14 bg-white rounded-full p-2 shadow-lg" style="left: 50%">
      <Spinner class="w-6 h-6" />
      <!-- isFocusOnFilteredData: {{ isFocusOnFilteredData }} -->
    </div>

    <div class="absolute z-50 bottom-5 right-10 py-2 px-3" :class="{'mt-9': !!projectId}">
      <div class="flex items-end justify-end">
        <DialogCreate
          :drawedFeatures="drawedData?.length > 0 ? drawedData : []"
          @startDraw="(type) => startDraw(type)"
          @deleteFeature="(feature) => deleteFeature(feature)"
          @focusOnFeature="(feature) => focusOnFeature(feature)"
          @previewFeature="(feature) => previewFeature(feature)"
          @resetDrawing="() => resetDrawing()"
        />
      </div>
    </div>
  </div>
</template>

<style>
.user-beacon {
  background-size: cover;
  background-position: center;
}

.slideRight-enter-active, .slideRight-leave-active {
  transition: transform 0.1s ease-in-out, opacity 0.1s ease-in-out;
}
.slideRight-enter-from, .slideRight-leave-to {
  transform: translateX(-3%);
  opacity: 0;
}
</style>
