<script setup>
import { useRouter } from 'vue-router'
import { Button } from '@/components/ui/button'
import { ChevronLeft, X } from 'lucide-vue-next'
const router = useRouter()
</script>

<template>
  <Button @click="router.push({name: 'Dashboard'})" size="sm" variant="ghost" class="w-8 h-8 p-0 rounded-full">
    <X class="text-gray-500" />
  </Button>
</template>
