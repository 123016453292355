<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import ProfileForm from './ProfileForm.vue'
import {Button} from "@/components/ui/button"
import {ChevronLeft} from "lucide-vue-next"
import {Avatar, AvatarImage} from "@/components/ui/avatar"

import { readAndCompressImage } from 'browser-image-resizer'

const store = useStore()
const member = computed(() => store.getters.getMember)

const fileInput = ref(null)

const updateAvatar = async () => {
  const file = fileInput.value.files[0]
  const resizeConfig = {
    quality: 0.8,
    maxWidth: 400,
    maxHeight: 400,
    debug: true
  }
  const resizedFile = await readAndCompressImage(file, resizeConfig)
  const fileSize = resizedFile.size / 1024 / 1024

  if (fileSize > 1) {
    store.commit('SET_ALERT', {type: 'warning', text: 'File size must be less than 1MB'})
    return
  }

  await store.dispatch('user/updateAvatar', {
    file: resizedFile
  })
}
</script>

<template>
  <div class="flex items-center justify-between">
    <div class="flex items-center p-3">
      <Button size="sm" variant="ghost" class="w-8 p-0 h-8 mr-2 rounded-full" @click="$router.go(-1)">
        <ChevronLeft class="text-gray-500" />
      </Button>
      <h2 class="text-lg font-bold">Profile Settings</h2>
    </div>
  </div>
  <div class="pb-2">
    <div class="p-3">
      <div class="flex flex-col mb-4">
        <div class="mb-2">
          <Avatar class="w-28 h-28 cursor-pointer hover:opacity-80">
            <AvatarImage :src="member.avatar_url" :alt="member.display_name" />
          </Avatar>
        </div>
        <Button variant="secondary" size="sm" @click="fileInput.click()">Change avatar</Button>
        <div class="max-w-[250px]">
          <input ref="fileInput" type="file" style="display: none;" accept="image/jpeg, image/png" @change="updateAvatar">
        </div>
      </div>
      <div>
        <ProfileForm v-if="member.member_id" :member="member" />
      </div>
    </div>
  </div>
</template>
