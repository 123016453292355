<script setup>
import {ref, computed, onBeforeMount, onMounted, onBeforeUnmount, watch} from 'vue'
import {API, HOST} from '@/utils/http'
import mapboxgl from 'mapbox-gl'

import {Button} from '@/components/ui/button'
import {Slider} from '@/components/ui/slider'
import {Label} from '@/components/ui/label'
import {DialogConfirm} from '@/components/dialogs'
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select"

import {useToast} from '@/components/ui/toast'
import axios from 'axios'

const { toast } = useToast()

const props = defineProps({
  feature: Object,
  map: Object
})


const originGeometry = ref(props.feature.geometry)
const editedGeometry = ref(originGeometry.value)

const matchingProfileChoices = ref([
  {value: 'driving', label: 'Driving'},
  {value: 'walking', label: 'Walking'},
  {value: 'cycling', label: 'Cycling'},
  {value: 'driving-traffic', label: 'Driving with traffic'}
])
const matchingProfile = ref(matchingProfileChoices.value[0].value)

const pointsCollection = ref(null)
const filterKeys = computed(() => {
  return pointsCollection.value ? pointsCollection.value.reduce((acc, point) => {
    for (const [key, value] of Object.entries(point)) {
      if (['lat', 'lng', 'ismoving', 'created_origin', 'id', 'battery_level'].includes(key)) {
        continue
      }

      if (acc[key]) {
        acc[key].value = [Math.min(acc[key].value[0], value), Math.max(acc[key].value[1], value)]
        acc[key].label = key
      } else {
        acc[key] = {value: [value, value], label: key}
      }
    }

    return acc
  }, {}) : null
})

const totalPoints = computed(() => originGeometry.value.coordinates.length)
const totalPointsFiltered = ref(0)
const sliderValues = ref({})


const revert = () => {
  sliderValues.value = {}
  props.map.getSource('page-report-mixed').setData({
    type: 'FeatureCollection',
    features: [{
      type: 'Feature',
      geometry: originGeometry.value,
      properties: props.feature.properties
    }]
  })
}

const saveGeometry = async () => {
  try {
    const response = await API({
      method: 'PUT',
      url: `${HOST}/geo/feature/${props.feature.properties.code}/geometry`,
      data: editedGeometry.value
    })
    toast({title: 'Geometry updated'})
  } catch (e) {
    toast({title: e})
  }
}

const getClosestPoint = (e) => {
  const { coordinates } = editedGeometry.value

  let closestPoint = coordinates[0]
  let minDistance = Infinity

  for (const coord of coordinates) {
    const [lon, lat] = coord
    const { x, y } = props.map.project([lon, lat])
    const distance = Math.hypot(x - e.point.x, y - e.point.y)

    if (distance < minDistance) {
      minDistance = distance
      closestPoint = coord
    }
  }

  return closestPoint
}

const handleMouseMove = (e) => {
  const closestPoint = getClosestPoint(e)
  if (props.map.getLayer('hover-marker-layer')) {
    props.map.getSource('hover-marker-source').setData({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: closestPoint
      }
    })
  }
}

const selectedSegmentPoints = ref([])
const selectedSegment = ref(null)

const getSelectedSegmentCoords = () => {
  if (selectedSegmentPoints.value.length !== 2) {
    return null
  }
  const start = selectedSegmentPoints.value[0]
  const end = selectedSegmentPoints.value[1]

  const startIndex = editedGeometry.value.coordinates.findIndex(coord => coord === start)
  const endIndex = editedGeometry.value.coordinates.findIndex(coord => coord === end)

  if (startIndex > endIndex) {
    return null
  }

  const segment = editedGeometry.value.coordinates.map((coord, index) => {
    if (index >= startIndex && index <= endIndex) {
      return coord
    }
    return null
  }).filter(coord => coord !== null)

  return segment
}

const getSnapedCoords = async (coords) => {
  const profile = matchingProfile.value
  const newCoords = coords.join(';')
  const radius = coords.map(() => 25)

  try {
    const response = await axios.get(
      `https://api.mapbox.com/matching/v5/mapbox/${profile}/${newCoords}?geometries=geojson&radiuses=${radius.join(';')}&steps=true&access_token=pk.eyJ1IjoiZWFydGhwYmMiLCJhIjoiY2x1dGRiMGU4MDdrdjJsb2ducG5pbG41ayJ9.XiX9hVsMSiOXSPf78V3dag`
    )

    return response.data.matchings[0].geometry.coordinates
  } catch (e) {
    toast({title: e})
  }
}

const getUpdatedGeometry = async (segmentGeometry) => {
  if (selectedSegmentPoints.value.length !== 2) {
    return null
  }

  const coordinates = editedGeometry.value.coordinates
  const start = selectedSegmentPoints.value[0]
  const end = selectedSegmentPoints.value[1]
  const startIndex = coordinates.findIndex(coord => coord === start)
  const endIndex = coordinates.findIndex(coord => coord === end)

  return {
    type: 'LineString',
    coordinates: [
      ...coordinates.slice(0, startIndex + 1),
      ...segmentGeometry.coordinates,
      ...coordinates.slice(endIndex)
    ]
  }
}

const resetSelectedSegment = () => {
  selectedSegmentPoints.value = []
  selectedSegment.value = null

  if (props.map.getSource('selected-segment-source')) {
    props.map.getSource('selected-segment-source').setData({
      type: 'FeatureCollection',
      features: []
    })
  }

  if (props.map.getSource('segment-points-source')) {
    props.map.getSource('segment-points-source').setData({
      type: 'FeatureCollection',
      features: []
    })
  }
}

const handleClick = async (e) => {
  const closestPoint = getClosestPoint(e)

  selectedSegmentPoints.value.push(closestPoint)

  props.map.getSource('segment-points-source').setData({
    type: 'FeatureCollection',
    features: selectedSegmentPoints.value.map(point => {
      return {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: point
        }
      }
    })
  })

  if (selectedSegmentPoints.value.length === 2) {
    const segmentCoords = getSelectedSegmentCoords()

    if (!segmentCoords) {
      toast({title: 'Invalid segment. Check direction.'})
      resetSelectedSegment()
      return
    }

    if (segmentCoords.length >= 100) {
      toast({title: 'Segment is too long. Maximum 100 points allowed.'})
      resetSelectedSegment()
      return
    }

    const snapedSegmentCoords = await getSnapedCoords(segmentCoords)

    selectedSegment.value = {
      type: 'LineString',
      coordinates: snapedSegmentCoords
    }

    props.map.getSource('selected-segment-source').setData({
      type: 'FeatureCollection',
      features: [{
        type: 'Feature',
        properties: {},
        geometry: selectedSegment.value
      }]
    })
  }

  if (selectedSegmentPoints.value.length === 3) {
    resetSelectedSegment()
  }
}

const applySelectedSegment = async () => {
  const newGeometry = await getUpdatedGeometry(selectedSegment.value)

  editedGeometry.value = newGeometry

  props.map.getSource('page-report-mixed').setData({
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: props.feature.properties,
        geometry: editedGeometry.value
      }
    ]
  })

  resetSelectedSegment()
}

const handleSliderChange = (key, value) => {
  sliderValues.value[key] = value

  const filteredPoints = pointsCollection.value.filter((point) => {
    for (const [key, value] of Object.entries(sliderValues.value)) {
      if (point[key] < value[0] || point[key] > value[1]) {
        return false
      }
    }

    return true
  })

  totalPointsFiltered.value = totalPoints.value - filteredPoints.length

  editedGeometry.value = {
    type: 'LineString',
    coordinates: filteredPoints.map(point => [point.lng, point.lat])
  }

  props.map.getSource('page-report-mixed').setData({
    type: 'FeatureCollection',
    features: [{
      type: 'Feature',
      geometry: editedGeometry.value,
      properties: props.feature.properties
    }]
  })
}

onBeforeMount(async () => {
  props.map.addSource('hover-marker-source', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: []
    }
  })

  props.map.addSource('segment-points-source', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: []
    }
  })

  props.map.addSource('selected-segment-source', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: []
    }
  })

  props.map.addLayer({
    id: 'selected-segment-layer',
    type: 'line',
    source: 'selected-segment-source',
    paint: {
      'line-color': '#000000',
      'line-width': 2
    }
  })

  props.map.addLayer({
    id: 'hover-marker-layer',
    type: 'circle',
    source: 'hover-marker-source',
    paint: {
      'circle-radius': 8,
      'circle-color': '#8d84f1',
      'circle-stroke-width': 2,
      'circle-stroke-color': '#fff'
    }
  })

  props.map.addLayer({
    id: 'segment-points-layer',
    type: 'circle',
    source: 'segment-points-source',
    paint: {
      'circle-radius': 8,
      'circle-color': '#000000',
      'circle-stroke-width': 2,
      'circle-stroke-color': '#fff'
    }
  })

  try {
    const response = await API({
      method: 'GET',
      url: `${HOST}/geo/feature/${props.feature.properties.code}/points`
    })
    pointsCollection.value = response.data.length ? response.data : null
  } catch (e) {
    toast({title: e})
  }
})

onMounted(() => {
  props.map.on('mousemove', handleMouseMove)
  props.map.on('click', handleClick)
})

onBeforeUnmount(() => {
  props.map.off('mousemove', handleMouseMove)
  props.map.off('click', handleClick)

  if (props.map.getSource('hover-marker-source')) {
    props.map.removeLayer('hover-marker-layer')
    props.map.removeSource('hover-marker-source')
  }

  if (props.map.getSource('segment-points-source')) {
    props.map.removeLayer('segment-points-layer')
    props.map.removeSource('segment-points-source')
  }

  if (props.map.getSource('selected-segment-source')) {
    props.map.removeLayer('selected-segment-layer')
    props.map.removeSource('selected-segment-source')
  }

  resetSelectedSegment()
})
</script>

<template>
<div>
  <div class="p-3">
    <h3 class="text-lg font-bold mb-2">Geometry editing</h3>

    <div class="mb-3">
      <h3 class="font-bold">Snaping to roads</h3>
      <p class="text-gray-500">Click on the map to select segment</p>
    </div>
    <div class="mb-3">
      <Label class="mb-2">Profile</Label>
      <Select v-model="matchingProfile">
        <SelectTrigger>
          <SelectValue placeholder="Select profile" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem
              v-for="(p, index) of matchingProfileChoices" :key="index" :value="`${p.value}`">
              {{p.label}}
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>

    <div v-if="selectedSegment" class="flex justify-between">
      <Button @click="resetSelectedSegment" variant="secondary" size="sm">Revert</Button>
      <Button @click="applySelectedSegment" size="sm">Apply snaping</Button>
    </div>
  </div>

  <div class="p-3" v-if="pointsCollection">
    <div class="mb-3">
      <h4 class="font-bold">Points filtering</h4>
      <p class="text-gray-500">Move sliders until you get desired result</p>
      <p class="text-gray-500 mt-1">Filtered {{ totalPointsFiltered }} points from {{ totalPoints }}</p>
    </div>

    <div class="space-y-4">
      <div v-for="key in Object.values(filterKeys)">
        <Label class="mb-2 capitalize">{{ key.label }}</Label>
        <Slider
          :default-value="key.value"
          :min="key.value[0]"
          :max="key.value[1]"
          :step="0.1"
          range
          multiple
          @update:model-value="handleSliderChange(key.label, $event)"
        />
        <div class="flex justify-between mt-2">
          <span class="text-xs text-gray-500">{{ sliderValues[key.label] ? sliderValues[key.label][0] : key.value[0] }}</span>
          <span class="text-xs text-gray-500">{{ sliderValues[key.label] ? sliderValues[key.label][1] : key.value[1] }}</span>
        </div>
      </div>
    </div>

    <!-- <div class="p-2 bg-gray-100">
      <pre class="text-sm">{{ sliderValues }}</pre>
    </div> -->
  </div>

  <div class="p-3 flex justify-between">
    <Button variant="secondary" @click="revert">Revert all changes</Button>
    <DialogConfirm
      @confirm="saveGeometry"
      title="Update geometry"
      description="Are you sure you want to save this geometry?"
    >
      <Button>Save</Button>
    </DialogConfirm>
  </div>
</div>
</template>
