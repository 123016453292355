<script setup>
import {ref} from 'vue'
import {useStore} from 'vuex'
import {toTypedSchema} from '@vee-validate/zod'
import * as z from 'zod'

import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/toast'
import { PlusCircle } from 'lucide-vue-next'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {
  Select, 
  SelectContent, 
  SelectGroup, 
  SelectItem, 
  SelectTrigger, 
  SelectValue
} from '@/components/ui/select'
import {
  Popover, 
  PopoverTrigger,
  PopoverContent
} from '@/components/ui/popover'

const store = useStore()

const props = defineProps({
  feature: {
    type: Object,
    required: true
  }
})

const sourceTypes = [
  { value: 'forest_carbon_diligence_30m', label: 'Forest Carbon Diligence 30m' },
]

const sourceIds = [
  { value: 'ABOVEGROUND_CARBON_DENSITY_v1.0.0_30', label: 'Aboveground Carbon Density 30m (v1.0.0)' },
  { value: 'CANOPY_HEIGHT_v1.0.0_30', label: 'Canopy Height 30m (v1.0.0)' },
  { value: 'CANOPY_COVER_v1.0.0_30', label: 'Canopy Cover  30m (v1.0.0)' }
]

const isOpen = ref(false)
const close = () => {
  isOpen.value = false
}

const formSchema = toTypedSchema(z.object({
  name: z.string().min(3).max(255).default('FCD Report (30m)'),
  layerType: z.string().default('forest_carbon_diligence_30m'),
  layerId: z.string().default('ABOVEGROUND_CARBON_DENSITY_v1.0.0_30'),
  start_date: z.date().default(new Date(Date.UTC(2013, 0, 1))),
  end_date: z.date().default(new Date(Date.UTC(2022, 0, 1)))
}))

const onSubmit = async (values) => {
  const payload = {
    project_id: props.feature.properties.project_id,
    feature_uuid: props.feature.properties.code,
    name: values.name,
    type: 1,
    start_date: values.start_date,
    end_date: values.end_date,
    source_type: values.layerType,
    source_id: values.layerId
  }

  store.dispatch('planet/createLayer', payload)
  
  toast({
    title: 'Report acquired.',
    description: 'Wait for the report to be generated.'
  })

  close()
}
</script>

<template>
  <Dialog :open="isOpen" @update:open="isOpen = $event">
    <DialogTrigger>
      <slot>
        <Button class="px-2" variant="ghost">
          <PlusCircle class="text-gray-500 w-4 h-4" />
        </Button>
      </slot>
    </DialogTrigger>
    <DialogContent class="max-w-[350px]">
      <DialogHeader>
        <DialogTitle>Create report</DialogTitle>
        <DialogDescription></DialogDescription>
      </DialogHeader>

      <Form class="space-y-3" :validation-schema="formSchema" @submit="onSubmit">
        <FormField v-slot="{ componentField }" name="name">
          <FormItem>
            <FormLabel>Name</FormLabel>
            <FormControl>
              <Input v-bind="componentField" />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ componentField }" name="layerType">
          <FormItem>
            <FormLabel>Source type</FormLabel>
            <FormControl>
              <Select v-bind="componentField">
                <SelectTrigger>
                  <SelectValue placeholder="Source type" />
                </SelectTrigger>
                <SelectContent class="max-h-[300px]">
                  <SelectGroup>
                    <SelectItem
                      v-for="(i, index) of sourceTypes"
                      :key="index"
                      :value="i.value"
                    >
                      {{i.label}}
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ componentField }" name="layerId">
          <FormItem>
            <FormLabel>Source ID</FormLabel>
            <FormControl>
              <Select v-bind="componentField">
                <SelectTrigger>
                  <SelectValue placeholder="Source id" />
                </SelectTrigger>
                <SelectContent class="max-h-[300px]">
                  <SelectGroup>
                    <SelectItem
                      v-for="(i, index) of sourceIds"
                      :key="index"
                      :value="i.value"
                    >
                      {{i.label}}
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ componentField }" name="start_date">
          <FormItem>
            <FormLabel>Start date</FormLabel>
            <Popover>
              <PopoverTrigger class="w-full">
                <Input v-bind="componentField" disabled />
              </PopoverTrigger>
            </Popover>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ componentField }" name="end_date">
          <FormItem>
            <FormLabel>End date</FormLabel>
            <Popover>
              <PopoverTrigger class="w-full">
                <Input v-bind="componentField" disabled />
              </PopoverTrigger>
            </Popover>
            <FormMessage />
          </FormItem>
        </FormField>

        <DialogFooter class="mt-4">
          <Button variant="ghost" @click="close" type="button">Close</Button>
          <Button type="submit">Create</Button>
        </DialogFooter>
      </Form>
    </DialogContent>
  </Dialog>
</template>
